import { NavLink } from 'react-router-dom'
import i18n from '~/i18n'

import { LogoStore } from '~/components/LogoStore'
import { formatMoney, formatNumberWithDots, formatPercent } from '~/helpers'

const { t } = i18n

export const schema: TableSchema<AdvertiserWithMetrics> = {
  title: {
    title: t('rm:Advertiser'),
    sortKey: 'name',
    render: rowData => (
      <NavLink
        to={`/retail-media/campaigns?rmid=${rowData.id}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LogoStore
          alt={rowData.title}
          linkLogo={rowData.logo_url}
          backgroundLogo={rowData.account_theme.header_color}
          size="small"
        />
        <div style={{ lineHeight: 1, marginLeft: '.5rem' }}>
          <strong className="title">{rowData.title}</strong>
        </div>
      </NavLink>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true
  },
  balance: {
    title: t('rm:balanceInWallet'),
    sortKey: 'balance',
    sortInitialToggleDirection: 'DESC',
    render: rowData => (
      <div style={{ lineHeight: 1.125 }}>
        {rowData?.balance}
        {rowData?.checkingAccountId && (
          <>
            <br />
            <NavLink
              to={`/retail-media/wallet/${rowData.checkingAccountId}?rmid=${rowData.id}`}
            >
              <small>{t('rm:accessWallet')}</small>
            </NavLink>
          </>
        )}
      </div>
    )
  },
  daily_budget: {
    title: t('rm:dailyBudget'),
    sortKey: 'total_daily_budget',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.total_daily_budget
        ? formatMoney(rowData?.metrics?.total_daily_budget?.value)
        : '-'
  },
  actives_campaigns: {
    title: t('rm:Campaigns'),
    sortKey: 'total_campaigns',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.actives_campaigns
        ? formatNumberWithDots(rowData.metrics.actives_campaigns.value)
        : '-'
  },
  impressions: {
    title: t('rm:metrics.impressions'),
    sortKey: 'impressions',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.prints
        ? formatNumberWithDots(rowData.metrics.prints.value)
        : '-'
  },
  clicks: {
    title: t('rm:metrics.clicks'),
    sortKey: 'clicks',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.clicks
        ? formatNumberWithDots(rowData.metrics.clicks.value)
        : '-'
  },
  ctr: {
    title: t('rm:metrics.ctr'),
    helpText: t('rm:metrics.ctr.tooltip'),
    sortKey: 'ctr',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.ctr ? formatPercent(rowData.metrics.ctr.value) : '-'
  },
  adCost: {
    title: t('rm:metrics.adCost'),
    sortKey: 'adcost',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.adCost
        ? formatPercent(rowData.metrics.adCost.value)
        : '-'
  },
  total_spent: {
    title: t('rm:metrics.adsRevenue'),
    sortKey: 'total_spent',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.total_spent
        ? formatMoney(Number(rowData.metrics.total_spent.value))
        : '-'
  },
  conversion: {
    title: t('rm:metrics.conversions'),
    sortKey: 'conversions',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.conversion
        ? formatNumberWithDots(rowData.metrics.conversion.value)
        : '-'
  },
  conversion_rate: {
    title: t('rm:metrics.conversion_rate'),
    sortKey: 'conversion_rate',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.conversion_rate
        ? formatPercent(rowData.metrics.conversion_rate.value)
        : '-'
  },
  sales: {
    title: t('rm:metrics.income'),
    sortKey: 'income',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.income ? formatMoney(rowData.metrics.income.value) : '-'
  },
  roas: {
    title: t('rm:metrics.roas'),
    helpText: t('rm:metrics.roas.tooltip'),
    sortKey: 'roas',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      rowData?.metrics?.roas
        ? formatNumberWithDots(rowData.metrics.roas.value)
        : '-'
  }
}
