import { useCallback, useMemo, useRef, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import {
  createCampaignKey,
  createCampaignService
} from '~/modules/retailMedia/services/campaign'

import Investment from '../../elements/Investment'
import Places from '../../elements/Places'
import { Video } from '../../elements/Video'
import { DTOCreateDigitalSignageCampaign } from './dto'

// import { Container } from './styles';

const DigitalSignage: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const [loading, setLoading] = useState(false)
  const [bodyRequest, setBodyRequest] = useState(
    {} as DTOCreateDigitalSignageCampaignResponse
  )

  const rmid = useQueryParams().get('rmid')

  /**
   * Form is ready
   * Valor para controlarmos se devemos bloquear o submit
   */
  const formIsReadyToSubmit = useMemo(() => true, [])

  /**
   * Handle request
   */
  const createCampaign = useMutation({
    mutationKey: [createCampaignKey],
    mutationFn: createCampaignService,
    onSuccess: response => {
      setLoading(false)

      history.push(`/retail-media/campaign/view/${response.data.id}`)
      toastr.success(
        t('rm:campaign.create.toast.success.title'),
        t('rm:campaign.create.toast.success.message')
      )
    },
    onError: () => {
      setLoading(false)

      toastr.error(
        t('rm:campaign.create.toast.error.title'),
        t('rm:campaign.create.toast.error.message')
      )
    }
  })

  /**
   * Handle submit
   */
  const { lengthCentsCurrency } = useAppInfo()

  const handleSubmit = useCallback(
    async (formData: CreateDigitalSignageFormData) => {
      const preData = {
        ...formData,
        publisher_id: rmid,
        lengthCentsCurrency
      }

      const body = DTOCreateDigitalSignageCampaign(preData)

      setLoading(true)

      await createCampaign.mutateAsync(body)
    },
    [createCampaign, lengthCentsCurrency, rmid]
  )

  /**
   * Handle form change
   */
  const handleFormChange = () => {
    const formData = formRef?.current?.getData() as
      | CreateDigitalSignageFormData
      | undefined

    return setBodyRequest(
      DTOCreateDigitalSignageCampaign({ ...formData, publisher_id: rmid })
    )
  }

  /**
   * initialData
   */
  const initialData: Partial<CreateDigitalSignageFormData> = {
    places: {
      placesCheck: 'all',
      place_ids: [],
      all: ''
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      onChange={handleFormChange}
      initialData={initialData}
    >
      <NameAndTimeInterval
        formRef={formRef}
        titlePanel={t('rm:campaign.create.NameAndTimeInterval.titlePanel')}
        labelTitle={t('rm:campaign.create.NameAndTimeInterval.labelTitle')}
        placeholderTitle={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderTitle'
        )}
        hasDescription
        labelDescription={t(
          'rm:campaign.create.NameAndTimeInterval.labelDescription'
        )}
        placeholderDescription={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderDescription'
        )}
        onlyDates
        showValidBadgeOnSection
      />

      <Places />

      <Video formRef={formRef} />

      <Investment formData={bodyRequest} />

      <div className="form-default__buttons">
        <Button
          type="submit"
          text={t('rm:sendForApproval')}
          loading={loading}
          size="large"
          template="success"
          disabled={!formIsReadyToSubmit}
        />
      </div>
    </Form>
  )
}

export default DigitalSignage
