import React, { useCallback } from 'react'
import { FiCheck, FiPlus, FiTrash, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'
import PropTypes from 'prop-types'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import ThumbProduct from '~/components/ThumbProduct'
import Tooltip from '~/components/Tooltip'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import {
  addProductOnCampaign,
  deleteProductOnCampaign
} from '../../store/campaign/actions'
import modelProduct from './model'

import * as S from './styles'

interface Props {
  data: PublisherProduct
  isSelected?: boolean
  selectedSKUS?: null | string[]
  selectedIDs?: null | string[]
  hasAction?: boolean
  itemsDisableds?: null | IndustryCampaignViewResponseAd[]
}

/**
 * Componente responsável por renderizar um produto nas listagens.
 * @param {Object} data Dados do produto
 * @param {boolean} isSelected Informa se o produto está na lista dos selecionados
 * @param {string[]} selectedSKUS Informa os SKUS selecionados para mudarmos o botão no painel de busca
 * @param {boolean} hasAction Informa se o produto está numa página de edição e deve exibir botões de ação
 */
const Product = ({
  data,
  isSelected = false,
  selectedSKUS = null,
  selectedIDs = null,
  hasAction = false,
  itemsDisableds = null
}: Props) => {
  const dispatch = useDispatch()
  const { tCapitalize } = useAPPTranslation()

  const product = modelProduct(data)

  const isSelectedOnSearch =
    selectedSKUS?.find(item => item === data.product_sku) ||
    selectedIDs?.find(item => item === data.id)

  const handleAddProduct = useCallback(
    () => dispatch(addProductOnCampaign({ product: data })),
    [data, dispatch]
  )

  const handleDeleteProduct = useCallback(
    () => dispatch(deleteProductOnCampaign({ product: data })),
    [data, dispatch]
  )

  const isDisabled = itemsDisableds?.find(
    item => item.product_sku === data.product_sku || item.id === data.id
  )

  return (
    <S.Container>
      {!!product?.image && (
        <S.Image className={!!data.item_disabled && 'disabled'}>
          <ThumbProduct url={product.image} title={product.name} />
        </S.Image>
      )}

      <S.Infos className={!!data.item_disabled && 'disabled'}>
        {product.name && (
          <div className="mainInfo">
            <S.Name>{product.name}</S.Name>
          </div>
        )}

        <div className="secondary">
          {product.product_sku ? (
            <S.InfoItem>SKU: {product.product_sku} </S.InfoItem>
          ) : (
            <AlertInfo text={t('rm:ProductsPanel.skuNotFound')} />
          )}
        </div>
      </S.Infos>

      {hasAction && (
        <S.Action>
          {isSelectedOnSearch ? (
            <S.SelectedOnSearch className={!!isDisabled && 'not-hover'}>
              <Button
                template="primary"
                text={tCapitalize('c:words.selected')}
                iconLeft={<FiCheck />}
                className="selectedItem"
                size="small"
              />

              <Button
                template="transparentDanger"
                text={tCapitalize('c:actions.remove')}
                iconLeft={<FiX />}
                onClick={handleDeleteProduct}
                className="removeItem"
                size="small"
                disabled={!!isDisabled}
              />
            </S.SelectedOnSearch>
          ) : (
            <>
              {isSelected ? (
                data.item_disabled ? (
                  <Tooltip
                    text={t('rm:ProductsPanel.alert.forbiddenRemoveProduct')}
                    icon={
                      <Button
                        template="ghost"
                        text={<FiCheck className="checkIcon" />}
                        size="xsmall"
                        customWidth="auto"
                        disabled={!!data.item_disabled}
                        className="buttonToolTipChecked disabled"
                      />
                    }
                    positionProp="left"
                  />
                ) : (
                  <Button
                    template="transparentDanger"
                    text={<FiTrash />}
                    onClick={handleDeleteProduct}
                    size="xsmall"
                    customWidth="auto"
                    disabled={!!data.item_disabled}
                  />
                )
              ) : (
                <Button
                  template="primaryOutlined"
                  text={t('c:actions.select')}
                  iconLeft={<FiPlus />}
                  onClick={handleAddProduct}
                  size="small"
                />
              )}
            </>
          )}
        </S.Action>
      )}
    </S.Container>
  )
}

export default Product

Product.defaultProps = {
  isSelected: false,
  selectedSKUS: null,
  hasAction: false
}

Product.propTypes = {
  selectedSKUS: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool,
  hasAction: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    account_id: PropTypes.string,
    location_id: PropTypes.string,
    store_id: PropTypes.string,
    external_id: PropTypes.string,
    product_id: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    }),
    current_stock: PropTypes.number,
    minimum_stock: PropTypes.number,
    price: PropTypes.number,
    promotional_price: PropTypes.number,
    gtin: PropTypes.string,
    additional_gtins: PropTypes.arrayOf(PropTypes.string),
    ncm: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    cest: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    categories: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      large: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      medium: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      small: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      )
    }),
    attributes: PropTypes.arrayOf(PropTypes.string),
    package: PropTypes.string,
    unit_weight: PropTypes.number,
    unit_measurement: PropTypes.string,
    weight_per_unit: PropTypes.number,
    indexed_at: PropTypes.string,
    additional_gtin: PropTypes.arrayOf(PropTypes.string),
    asset: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ),
    category: PropTypes.arrayOf(PropTypes.string),
    stock: PropTypes.number
  }).isRequired
}
