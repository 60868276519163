import styled from 'styled-components'

export const Shape = styled.div`
  --color: var(--warning);

  &[data-template='warning'] {
    --color: var(--warning);
  }

  &[data-template='neutral'] {
    --color: var(--gray);
  }

  &[data-template='success'] {
    --color: var(--success);
  }

  &[data-template='danger'] {
    --color: var(--danger);
  }

  &[data-template='info'] {
    --color: var(--info);
  }

  &[data-template='primary'] {
    --color: var(--primary);
  }

  &[data-template='secondary'] {
    --color: var(--secondary);
  }

  position: relative;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.outlineGray};
  border-radius: 8px;

  margin: 1rem 0;
  padding: ${({ theme }) => theme.spacings.medium};

  .closed {
    position: absolute;
    top: 0;
    right: 0;

    .icon-closed {
      font-size: ${({ theme }) => theme.font.sizes.medium};
      color: var(--danger);
    }
  }

  .info-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      font-size: ${({ theme }) => theme.font.sizes.xxxlarge};
      color: var(--color);
    }

    .title {
      color: var(--color);
      font-weight: 700;
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }

    .text {
      color: ${({ theme }) => theme.colors.stormGray};
      font-weight: 400;
      font-size: ${({ theme }) => theme.font.sizes.small};
    }
  }
`
