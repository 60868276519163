import { FiChevronRight } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import i18n from '~/i18n'

import { ButtonNavLink } from '~/components/Buttons'
import { LogoStore } from '~/components/LogoStore'
import Status from '~/components/Status'
import { formatDateToString } from '~/helpers'

import { statusType } from '../../StatusBar/statusType'

const { t } = i18n

export const schema: TableSchema = {
  advertiserName: {
    title: t('rm:Advertiser'),
    render: rowData => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LogoStore
          alt={rowData.raw?.advertiser_name}
          backgroundLogo={rowData.raw?.advertiser_account_theme?.header_color}
          linkLogo={rowData.raw?.advertiser_account_logo}
        />

        <div style={{ lineHeight: 1, marginLeft: '.5rem' }}>
          <strong className="title">{rowData.raw?.advertiser_name}</strong>
        </div>
      </div>
    )
  },
  campaignName: {
    title: t('rm:Campaign'),
    render: rowData => (
      <NavLink
        to={`/retail-media/campaign/view/${rowData.id}?callback_url=/retail-media/campaigns/review`}
        style={{ textDecoration: 'none', width: '100%' }}
        title={t('rm:clickToReview')}
      >
        <div style={{ lineHeight: 1 }}>
          <div className="item__name">
            {t('rm:Type')}: {rowData.adType}
          </div>
          <strong className="title">{rowData.title}</strong>
        </div>
      </NavLink>
    ),
    className: 'order__details col__infos col__title'
  },
  createdAt: {
    title: t('rm:creationDate'),
    render: rowData =>
      formatDateToString(rowData.createdAt, 'DD/MM/YY [às] HH:mm')
  },
  start: {
    title: t('rm:Start'),
    render: rowData => rowData.startDate
  },
  end: {
    title: t('rm:End'),
    render: rowData => rowData.endDate || t('rm:indefinite')
  },
  staatus: {
    title: t('rm:Status'),
    render: rowData => (
      <Status
        status={{
          label: statusType[rowData.status]?.label || rowData.status,
          type: statusType[rowData.status]?.color || 'default'
        }}
      />
    )
  },
  linkCampaign: {
    width: '196',
    render: rowData => (
      <ButtonNavLink
        to={`/retail-media/campaign/view/${rowData.id}?callback_url=/retail-media/campaigns/review`}
        size="small"
        text={t('rm:reviewCampaign')}
        iconRight={<FiChevronRight />}
      />
    )
  }
}
