import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  Ref,
  MutableRefObject
} from 'react'
import { Trans } from 'react-i18next'
import { FiList, FiSearch } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { FormHandles } from '@unform/core'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import { Button } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import { FilterSearch } from '~/components/FilterSearch'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { FormGroup, Label, Textarea } from '~/components/unform'
import { useAppInfo } from '~/hooks/useAppInfo'
import * as types from '~/modules/retailMedia/store/product/constants'
import { useAppSelector } from '~/store/hooks'
import { setQuery } from '~/store/modules/filters/actions'

import {
  addProducts,
  deleteAllProductsOnCampaign
} from '../../store/campaign/actions'
import { listProducts } from '../../store/product/actions'
import AlertBrands from '../AlertBrands'
import Product from '../Product'

import * as S from './styles'

type TabKeys = 'sku' | 'product'

interface Props {
  formRef?: Ref<HTMLFormElement> | MutableRefObject<FormHandles>
  isRequired?: boolean
  description?: string
  isEdit?: boolean
  isCollapsible?: boolean
  Buttons?: React.ReactNode
  initialData?: {
    publisher_name?: string
    publisher_id?: string
  }
}

const ProductsPanel = ({
  formRef,
  isRequired,
  description,
  isEdit = false,
  isCollapsible = true,
  Buttons,
  initialData
}: Props) => {
  const { isWhiteLabel } = useAppInfo()

  /**
   * Lógica de apoio
   */
  const { publisherId, publisherName } = useMemo(
    () => ({
      publisherId: initialData?.publisher_id,
      publisherName: initialData?.publisher_name
    }),
    [initialData]
  )

  /**
   * Lógica para a funcionalidade principal
   */

  const [bulkProducts, setBulkProducts] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<TabKeys>('product')

  // Busca de produto
  const dispatch = useDispatch()

  const {
    campaign: {
      products: { selectecProducts, loadingAddBulk, rejectedProducts = null }
    },
    product: {
      list: { data: searchProducts, loading: listProductsLoading }
    }
  } = useAppSelector(state => state.retailMedia)

  const { query } = useAppSelector(state => state.filters)

  const selectedSKUS = useMemo(
    () => selectecProducts.map(item => item.product_sku),
    [selectecProducts]
  )
  const selectedIDs = useMemo(
    () => selectecProducts.map(item => item.id),
    [selectecProducts]
  )
  const itemsDisableds = useMemo(() => {
    return selectecProducts.filter(item => item.item_disabled)
  }, [selectecProducts])

  const resetFieldQuery = useCallback(() => {
    dispatch(
      setQuery({
        query: { [types.PRODUCT_REDUX_QUERY]: null },
        keyState: types.PRODUCT_REDUX_QUERY
      })
    )
    dispatch(deleteAllProductsOnCampaign(isEdit))
  }, [dispatch, isEdit])

  const filteredQuery = useMemo(
    () => query?.[`${types.PRODUCT_REDUX_QUERY}`] || null,
    [query]
  )

  const queryStringSearchProduct = useMemo(
    () => ({
      quantity: 50,
      query: filteredQuery,
      publisher_id: publisherId
    }),
    [filteredQuery, publisherId]
  )

  useEffect(() => {
    resetFieldQuery()
  }, [resetFieldQuery])

  useEffect(() => {
    if (
      activeTab === 'product' &&
      filteredQuery &&
      (publisherId || isWhiteLabel)
    ) {
      dispatch(listProducts(queryStringSearchProduct))
    }
  }, [
    activeTab,
    dispatch,
    filteredQuery,
    publisherId,
    queryStringSearchProduct,
    isWhiteLabel
  ])

  const handleDeleteAllProducts = useCallback(
    () => dispatch(deleteAllProductsOnCampaign(isEdit)),
    [dispatch, isEdit]
  )

  const handleChangeProductsBulk = useCallback(e => {
    const data = e.target.value

    setBulkProducts(data)
  }, [])

  const handleAddProductsBulk = useCallback(() => {
    const body = {
      publisher_id: publisherId,
      quantity: 50,
      page: 0,
      product_skus: bulkProducts,
      isEdit
    }
    dispatch(addProducts({ body }))

    formRef.current.setFieldValue('bulkProducts', '')
  }, [bulkProducts, dispatch, formRef, isEdit, publisherId])

  const iconLeft = useMemo(
    () => <BadgeIsValid isValid={!!selectecProducts?.length} />,
    [selectecProducts]
  )

  const handleTabs = useCallback((tabKey: TabKeys) => setActiveTab(tabKey), [])

  return (
    <Panel
      title={t('rm:ProductsPanel.title')}
      description={description}
      isCollapsible={isCollapsible}
      {...(isRequired ? { iconLeft } : {})}
    >
      <S.Header>
        <AlertBrands publisherId={publisherId} publisherName={publisherName} />
      </S.Header>

      {/* Lista de pesquisa */}
      <S.ProductsLists>
        <S.ProductsList>
          <S.ProductsListHeader>
            <S.Nav>
              <Button
                template={activeTab === 'sku' ? 'primary' : 'light'}
                onClick={() => handleTabs('sku')}
                text={t('rm:ProductsPanel.addBySku')}
                size="small"
                iconLeft={<FiList />}
              />

              <Button
                template={activeTab === 'product' ? 'primary' : 'light'}
                onClick={() => handleTabs('product')}
                text={t('rm:ProductsPanel.searchProducts')}
                size="small"
                iconLeft={<FiSearch />}
              />
            </S.Nav>

            {activeTab === 'product' && (
              <FilterSearch
                placeholder={t('rm:ProductsPanel.searchProductsPlaceholder')}
                keyState={`${types.PRODUCT_REDUX_QUERY}`}
                live
                isDebounce
              />
            )}

            <Loading status={listProductsLoading}>
              {t('rm:ProductsPanel.loadingProducts')}...
            </Loading>

            {/* Esta seção permitirá o usuário adicionar vários produtos de uma vez */}
            {activeTab === 'sku' && (
              <S.BulkSection>
                <Loading status={loadingAddBulk}>
                  {t('rm:ProductsPanel.addingProducts')}...
                </Loading>

                {!!rejectedProducts?.length && (
                  <div className="alertsForm">
                    <AlertInfo
                      text={t('rm:ProductsPanel.alert.addProductError', {
                        skus: rejectedProducts.join(', ') as string
                      })}
                    />
                  </div>
                )}

                <FormGroup>
                  <Label htmlFor="bulkProducts" isRequired>
                    {t('rm:ProductsPanel.bulkProducts.label')}
                  </Label>
                  <Textarea
                    name="bulkProducts"
                    type="text"
                    // eslint-disable-next-line max-len
                    placeholder={t('rm:ProductsPanel.bulkProducts.placeholder')}
                    onChange={handleChangeProductsBulk}
                  />
                </FormGroup>

                <FormGroup className="actionButtons">
                  <Button
                    text={t('common:actions.add')}
                    onClick={handleAddProductsBulk}
                    disabled={!bulkProducts}
                  />
                </FormGroup>
              </S.BulkSection>
            )}
          </S.ProductsListHeader>

          {!listProductsLoading && activeTab === 'product' && filteredQuery && (
            <div>
              {searchProducts?.map(item => (
                <Product
                  hasAction
                  key={item.id}
                  data={item}
                  selectedSKUS={selectedSKUS}
                  selectedIDs={selectedIDs}
                  itemsDisableds={itemsDisableds}
                />
              ))}
            </div>
          )}
        </S.ProductsList>

        {/* Lista de selecionadas */}
        <S.ProductsList>
          <S.ProductsListHeader>
            <S.Heading>
              <S.Title>
                <Trans
                  i18nKey="rm:ProductsPanel.selectedProductsLength"
                  defaults="Produtos selecionados <span>({{count}} sku)</span>"
                  components={{ span: <span /> }}
                  values={{ count: selectecProducts.length || 0 }}
                />
              </S.Title>

              {!!selectecProducts?.length && (
                <Button
                  template="transparent"
                  text={t('c:actions.removeAll')}
                  onClick={handleDeleteAllProducts}
                  size="xsmall"
                />
              )}
            </S.Heading>

            {isEdit && (
              <S.Info>
                <AlertInfo
                  text={t('rm:ProductsPanel.alert.cpcOnEditing')}
                  template="info"
                />
              </S.Info>
            )}
          </S.ProductsListHeader>

          <div>
            {selectecProducts?.map(item => (
              <Product hasAction key={item.id} data={item} isSelected />
            ))}
          </div>

          {selectecProducts.length === 0 && (
            <div className="empty">
              <EmptyPage
                title={t('rm:ProductsPanel.empty.title')}
                subtitle={t('rm:ProductsPanel.empty.subtitle')}
                svgPath="photos/orders-empty"
              />
            </div>
          )}
        </S.ProductsList>
      </S.ProductsLists>

      {Buttons && Buttons}
    </Panel>
  )
}

export default ProductsPanel
