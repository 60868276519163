import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { history, getRole, getAccountId } from '~/helpers'

// import navigateTo from 'helpers/navigate-to';

export const AccessControl = ({ role, ComposedComponent, ...props }) => {
  const RedirectUser = () => {
    history.push('/')

    return <></>
  }

  const getPermission = () => {
    return getRole() === role
  }

  return (
    <>{getPermission() ? <ComposedComponent {...props} /> : <RedirectUser />}</>
  )
}

export const ControlByModule = ({ config, Component, ...props }) => {
  const { accountRestriction, accountsWithPermission } = config

  const checkAccountPermission = () => {
    const shouldVerify = !!accountRestriction

    if (!shouldVerify) return true

    const shouldShow = accountsWithPermission?.includes(getAccountId())

    return shouldShow
  }

  const RedirectUser = () => {
    history.push('/')

    return <></>
  }

  return (
    <>
      {checkAccountPermission() ? <Component {...props} /> : <RedirectUser />}
    </>
  )
}

AccessControl.propTypes = {
  // authenticated: PropTypes.bool,
  // location: PropTypes.object,
  role: PropTypes.string.isRequired,
  ComposedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]).isRequired
}

export function AccessControlByModule({ config, Component }) {
  return connect(
    () => ({
      Component,
      config
    }),
    {}
  )(ControlByModule)
}

/**
 * @param {any} role
 * @param {any} ComposedComponent
 */
// eslint-disable-next-line react/display-name
export default AccessControl
