import React from 'react'

import ThumbZoomable from '~/components/ThumbZoomable'
import { getRetailMediaAccountType } from '~/helpers'
import HandleAdsActive from '~/modules/retailMedia/components/HandleAdsActive'
import { TagNewItem } from '~/modules/retailMedia/components/TagNewItem'

import * as S from './styles'

const isAdvertiser = getRetailMediaAccountType() === 'advertiser'

const AdInfo: RowComponent = data => {
  const showToggle = isAdvertiser && data.ad_type !== 'sponsored_brand'
  const logoUrl =
    data.campaign_settings.assets?.[0]?.url || data.campaign_settings.logo_url

  return (
    <S.TitleWrapper>
      {/* Toggle para gerenciar atividade do anúncios. Apenas advertiser */}
      {showToggle && (
        <aside>
          <HandleAdsActive key={data.id} data={data} isolatedAd />
        </aside>
      )}

      {data.ad_type === 'sponsored_brand' && (
        <>
          <ThumbZoomable
            url={logoUrl}
            title={data.campaign_settings.brand_name}
          />

          <div className="mainInfo">
            <strong className="title">{data.campaign_settings.headline}</strong>
            <span className="description">
              {data.campaign_settings.description}
            </span>
            <br />
            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}

      {data.ad_type === 'product' && (
        <>
          <ThumbZoomable url={data.image_url} title={data.name} />

          <div className="mainInfo">
            <strong className="title">{data.name}</strong>
            <span className="description">SKU: {data.product_sku}</span>
            <br />
            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}

      {data.ad_type === 'banner' && (
        <>
          <ThumbZoomable
            url={data.settings.media_url}
            title={data.media_size}
          />

          <div className="mainInfo">
            <small>
              <strong>Formato:</strong> {data.settings.ad_size}
            </small>
            <br />
            <span className="description" title="Data de criação">
              <TagNewItem created={data.created_at} />
            </span>
          </div>
        </>
      )}
    </S.TitleWrapper>
  )
}

export default AdInfo
