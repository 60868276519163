import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  formatDateToString,
  getRetailMediaAccountType,
  getWhiteLabelPublisherId
} from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import Card from '~/modules/retailMedia/components/Card'
import Chart from '~/modules/retailMedia/components/Chart'
import WrapperChartAndCards from '~/modules/retailMedia/components/WrapperChartAndCards'
import { ADVERTISERS_LIST_QUERY } from '~/modules/retailMedia/store/advertiser/constants'
import { PUBLISHERS_LIST_QUERY } from '~/modules/retailMedia/store/industry/publisher/constants'
import {
  reportCampaigns,
  reportCampaignsHistory
} from '~/modules/retailMedia/store/report/actions'
import { useAppSelector } from '~/store/hooks'

import cardsByType from './cards'
import { dashboardIcons } from './cards/icons'

import * as S from './styles'

type DashboardRetailMediaCampaignsProps = {
  origin?: DashboardRetailMediaOrigin
}

const DashboardRetailMediaCampaigns: React.FC<
  DashboardRetailMediaCampaignsProps
> = ({ origin }) => {
  const dispatch = useDispatch()
  const accountType = getRetailMediaAccountType()
  const isAdvertiser = accountType === 'advertiser'

  const searchParams = useQueryParams()

  const paramId = searchParams.get('rmid')

  const { isWhiteLabel } = useAppInfo()

  const id = useMemo(
    () => (isWhiteLabel && isAdvertiser ? getWhiteLabelPublisherId() : paramId),
    [isAdvertiser, isWhiteLabel, paramId]
  )

  /**
   * Redux states
   */
  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const {
    report: {
      get: { data: cardData, loading: cardLoading },
      getHistory: { data: chartData, loading: chartLoading, error: chartError }
    }
  } = useAppSelector(state => state.retailMedia)

  const { retailMedia: retailMediaFilters, query } = useAppSelector(
    state => state.filters
  )

  /**
   * 1. Requisição para os dados dos cards
   * 2. Requisição para os dados do gráfico
   */
  const requestKeyId = useMemo(() => {
    if (origin === 'sub_publisher') return 'publisher_id'
    return accountType === 'publisher' ? 'advertiser_id' : 'publisher_id'
  }, [origin, accountType])

  // Origin advertiser
  const advertisersParams = useMemo(
    () => ({
      advertiser_name: query?.[ADVERTISERS_LIST_QUERY] || null
    }),
    [query?.[ADVERTISERS_LIST_QUERY]]
  )

  // Origin publisher
  const publishersParams = useMemo(
    () => ({
      publisher_name: query?.[PUBLISHERS_LIST_QUERY] || null
    }),
    [query?.[PUBLISHERS_LIST_QUERY]]
  )

  //  Origin campaigns
  const campaignsParams = useMemo(
    () => ({
      ad_type: retailMediaFilters?.adType?.value,
      campaign_status: retailMediaFilters?.status?.value,
      campaign_name: retailMediaFilters?.campaignName || null
    }),
    [
      retailMediaFilters?.adType?.value,
      retailMediaFilters?.campaignName,
      retailMediaFilters?.status?.value
    ]
  )

  //  Origin ads
  const adsParams = useMemo(
    () => ({
      ad_type: retailMediaFilters?.adType?.value,
      ad_status: retailMediaFilters?.adStatus?.value,
      // API não suporta ainda
      product_sku: retailMediaFilters?.productSKU || null
    }),
    [
      retailMediaFilters?.adStatus?.value,
      retailMediaFilters?.adType?.value,
      retailMediaFilters?.productSKU
    ]
  )

  const queryParams = useMemo(
    () =>
      ({
        [requestKeyId]: id,
        start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
        end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
        ...(origin === 'advertiser' ? advertisersParams : {}),
        ...(origin === 'publisher' ? publishersParams : {}),
        ...(origin === 'campaign' ? campaignsParams : {}),
        ...(origin === 'ads' ? adsParams : {})
      }) as GetReportCampaignsActionProps,
    [
      requestKeyId,
      id,
      startDate,
      endDate,
      origin,
      advertisersParams,
      publishersParams,
      campaignsParams,
      adsParams
    ]
  )

  useEffect(() => {
    dispatch(reportCampaigns(queryParams))
    dispatch(reportCampaignsHistory(queryParams))
  }, [dispatch, queryParams])

  /**
   * Configuração dos cards
   */
  const icons = useMemo(() => dashboardIcons[accountType], [accountType])

  const cards = useMemo(() => {
    const formatter = id ? cardsByType : cardsByType.listAll

    const formatted = formatter[accountType]({
      data: cardData,
      loading: cardLoading
    })

    return formatted
  }, [accountType, cardData, cardLoading, id])

  return (
    <WrapperChartAndCards>
      <Chart
        chartData={chartData}
        loading={chartLoading}
        error={chartError}
        initialMetrics={['clicks', 'impressions', 'conversions']}
        additionalMetricsKeys={['totalSpent', 'income']}
        selectQuantity={3}
      />

      <div className="wrapperCards">
        <S.WrapperCard>
          <div className="hold-cards">
            {cards.map((item, index) => (
              <Card
                key={index}
                title={item.title}
                value={item.value}
                loading={item.loading}
                icon={icons?.[item.type]}
                isRelativeInterval={item.isRelativeInterval}
              />
            ))}
          </div>
        </S.WrapperCard>
      </div>
    </WrapperChartAndCards>
  )
}

export default DashboardRetailMediaCampaigns
