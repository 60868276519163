import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import {
  createCampaignKey,
  createCampaignService
} from '~/modules/retailMedia/services/campaign'
import { useAppSelector } from '~/store/hooks'

import ProductsPanel from '../../../ProductsPanel'
import AdvancedSettings from '../../elements/Advanced'
import Budget from '../../elements/Budget'
import * as S from '../../styles'
import { DTOCreateProductCampaign } from './dto'

const Product: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const [loading, setLoading] = useState(false)
  const [budgetValueIsValid, setBudgetValueIsValid] = useState(false)

  const { t } = useTranslation()

  const rmid = useQueryParams().get('rmid')

  /**
   * Handle products
   */
  const {
    campaign: {
      products: { selectecProducts }
    }
  } = useAppSelector(state => state.retailMedia)

  /**
   * Data to check costs
   */

  const formDataFinal = useMemo(() => {
    const products = selectecProducts?.map(item => ({
      id: item?.id,
      sku: item?.product_sku || item?.sku,
      categories: item?.categories
    }))

    return {
      name: 'name',
      start_date: new Date(),
      type: 'on_site',
      products
    }
  }, [selectecProducts])

  /**
   * Check data validity
   */
  const formIsValid = useMemo(() => {
    // product
    const productIsValid = !!formDataFinal?.products?.length

    const inputQueryProduct = formRef?.current?.getFieldRef('query')
    const productIsValidMessage = !productIsValid
      ? {
          text: t('rm:campaign.create.products.required'),
          action: () => inputQueryProduct?.focus?.()
        }
      : null

    const messages = [productIsValidMessage].filter(item => !!item)

    return {
      isValid: productIsValid,
      messages
    }
  }, [formDataFinal?.products?.length, t])

  /**
   * Form is ready
   * Valor para controlarmos se devemos bloquear o submit
   */
  const formIsReadyToSubmit = useMemo(
    () => budgetValueIsValid,
    [budgetValueIsValid]
  )

  /**
   * Handle request
   */
  const createCampaign = useMutation({
    mutationKey: [createCampaignKey],
    mutationFn: createCampaignService,
    onSuccess: response => {
      setLoading(false)

      history.push(`/retail-media/campaign/view/${response.data.id}`)
      toastr.success(
        t('rm:campaign.create.toast.success.title'),
        t('rm:campaign.create.toast.success.message')
      )
    },
    onError: () => {
      setLoading(false)

      toastr.error(
        t('rm:campaign.create.toast.error.title'),
        t('rm:campaign.create.toast.error.message')
      )
    }
  })

  /**
   * Handle submit
   */
  const { lengthCentsCurrency } = useAppInfo()

  const handleSubmit = useCallback(
    async (formData: CreateCampaignProductFormData) => {
      const products = selectecProducts?.map(item => ({
        id: item?.id,
        sku: item?.product_sku || item?.sku
      }))

      const preData = {
        ...formData,
        products,
        publisher_id: rmid,
        lengthCentsCurrency
      }

      const body = DTOCreateProductCampaign(preData)

      setLoading(true)

      await createCampaign.mutateAsync(body)
    },
    [createCampaign, lengthCentsCurrency, rmid, selectecProducts]
  )

  /**
   * Initial data
   */
  const initialData: Partial<CreateCampaignProductFormData> = {
    budget: { daily: null },
    placements: { placementsCheck: 'all' },
    settings: {
      budget_type: 'fast_as_possible'
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
      <NameAndTimeInterval
        formRef={formRef}
        labelTitle={t('rm:campaign.create.NameAndTimeInterval.labelTitle')}
        placeholderTitle={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderTitle'
        )}
        hasDescription
        labelDescription={t(
          'rm:campaign.create.NameAndTimeInterval.labelDescription'
        )}
        placeholderDescription={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderDescription'
        )}
        onlyDates
        endDateIsRequired={false}
        showValidBadgeOnSection
        intervalAlertMessage={[
          t('rm:campaign.create.NameAndTimeInterval.intervalAlertMessage')
        ]}
        showEndDateOptional
        titlePanel={t('rm:campaign.create.NameAndTimeInterval.titlePanel')}
      />

      <ProductsPanel
        formRef={formRef}
        initialData={{ publisher_id: rmid }}
        isRequired
        description={t('rm:campaign.create.ProductsPanel.description')}
      />

      {!!formIsValid.messages?.length && (
        <S.WrapperAlerts>
          {formIsValid.messages.map((message, index) => (
            <AlertInfo
              text={message?.text || message}
              key={index}
              onClick={message?.action}
              role={message?.action ? 'button' : 'text'}
            />
          ))}
        </S.WrapperAlerts>
      )}

      <Budget
        adType="product"
        publisherId={rmid}
        formData={formDataFinal}
        formIsValid={formIsValid}
        handleIsValid={setBudgetValueIsValid}
      />

      <hr />

      <AdvancedSettings />

      <div className="form-default__buttons">
        <Button
          type="submit"
          text={t('rm:createCampaign')}
          loading={loading}
          size="large"
          template="success"
          disabled={!formIsReadyToSubmit}
        />
      </div>
    </Form>
  )
}

export default Product
