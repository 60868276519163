import React, { useMemo } from 'react'

import Audio from './Audio'
import FilePDF from './FilePDF'
import Image from './Image'
import StatusTime from './StatusTime'
import Text from './Text'
import Video from './Video'

import { Container } from './styles'

const fileTypes = ['application', 'document']

const Message = ({ data, lastUsername = null, lastIsSent }: PropsMessage) => {
  const {
    userName = null,
    image,
    messageBody,
    createdAt,
    status,
    audio,
    video,
    fromStore,
    lastMessageAt,
    media = null
  } = data

  const isSent = useMemo(() => !!fromStore, [fromStore])

  const isGrouped = useMemo(
    () => lastUsername === userName && lastIsSent === fromStore,
    [fromStore, lastIsSent, lastUsername, userName]
  )

  const videoUrl = useMemo(
    () => (media?.type === 'video' ? media?.url : video),
    [media, video]
  )

  const isFile = useMemo(() => fileTypes.includes(media?.type), [media?.type])

  return (
    <Container
      isSent={isSent}
      isGrouped={isGrouped}
      hasImage={!!image}
      hasAudio={!!audio}
      hasVideo={!!video}
      hasFile={!!isFile}
    >
      <div className="wrap">
        <div className="message">
          {isFile && <FilePDF data={media} />}

          {image && !isFile && <Image data={image} />}

          {!!videoUrl && <Video data={videoUrl} />}

          {messageBody && <Text data={messageBody} />}

          {audio && <Audio data={audio} />}

          <StatusTime
            date={createdAt || lastMessageAt}
            isSent={isSent}
            status={status}
          />
        </div>
      </div>

      {isSent && !isGrouped && (
        <div className="senderName">{userName || (isSent && 'Bot')}</div>
      )}
    </Container>
  )
}

export default Message
