import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { useQuery } from '@tanstack/react-query'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import { Checkbox, FormGroup, Input, Label } from '~/components/unform'
import {
  getTermsOfServiceService,
  getTermsOfServiceServiceKey,
  patchTermsOfServiceService
} from '~/modules/retailMedia/services/configuration/terms-of-service'

const RetailMediaTermsOfServicePage: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const { data, isPending } = useQuery({
    queryKey: [getTermsOfServiceServiceKey],
    queryFn: async () => {
      const { data } = await getTermsOfServiceService()

      return data
    }
  })

  const handleSubmit = async (formData: PatchTermsOfServiceRequest) => {
    try {
      setLoading(true)
      await patchTermsOfServiceService(formData)
      toastr.success(
        t('rm:config.termsOfService.form.toast.success.title'),
        t('rm:config.termsOfService.form.toast.success.message')
      )
    } catch (error) {
      console
      toastr.error(
        t('rm:config.termsOfService.form.toast.error.title'),
        t('rm:config.termsOfService.form.toast.error.message')
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle title={t('rm:config.termsOfService.pageTitle')} />

      <HeaderPages
        title={t('rm:config.termsOfService.pageTitle')}
        subtitle={t('rm:config.termsOfService.subtitle')}
      />

      <Panel isLoading={isPending}>
        <Form
          onSubmit={handleSubmit}
          initialData={data}
          className="d-flex flex-column gap-4"
        >
          <FormGroup>
            <Checkbox
              name="active"
              label={t('rm:config.termsOfService.form.active.label')}
            />
          </FormGroup>

          <FormGroup>
            <Label
              htmlFor="url"
              text={t('rm:config.termsOfService.form.url.label')}
              helperText={t(
                'rm:config.termsOfService.form.url.labelHelperText'
              )}
            />
            <Input
              name="url"
              placeholder={t('rm:config.termsOfService.form.url.placeholder')}
            />
          </FormGroup>

          <FormGroup>
            <Label
              htmlFor="version"
              text={t('rm:config.termsOfService.form.version.label')}
              helperText={t(
                'rm:config.termsOfService.form.version.labelHelperText'
              )}
            />
            <Input
              name="version"
              placeholder={t(
                'rm:config.termsOfService.form.version.placeholder'
              )}
            />
          </FormGroup>

          <FormGroup className="actionsButtons">
            <Button
              type="submit"
              template="success"
              loading={loading}
              text={t('rm:config.termsOfService.form.submit.label')}
            />
          </FormGroup>
        </Form>
      </Panel>
    </>
  )
}

export default RetailMediaTermsOfServicePage
