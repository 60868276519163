import { formatMoney } from '~/helpers'

export const formatGroceryListAdvertisers: FormatAdvertisersList = data =>
  data.map(advertiser => ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/grocery/advertiser/list.ts'
    },
    id: advertiser.id,
    checkingAccountId: advertiser.checking_account_id,
    balance: formatMoney(advertiser.metrics.balance),
    title: advertiser.name,
    metrics: {
      total_daily_budget: {
        value: advertiser.metrics.total_daily_budget
      },
      actives_campaigns: {
        value: advertiser.metrics.total_campaigns
      },
      prints: {
        value: advertiser.metrics.impressions
      },
      clicks: {
        value: advertiser.metrics.clicks
      },
      income: {
        value: advertiser.metrics.income
      },
      ctr: {
        value: advertiser.metrics.ctr
      },
      adCost: {
        value: advertiser.metrics.adcost
      },
      conversion: {
        value: advertiser.metrics.conversions
      },
      conversion_rate: {
        value: advertiser.metrics.conversion_rate
      },
      total_spent: {
        value: advertiser.metrics.total_spent
      },
      roas: {
        value: advertiser.metrics.roas
      }
    },
    logo_url: advertiser.account_logo,
    account_theme: { ...advertiser.account_theme }
  }))
