import { AxiosError } from 'axios'

type HandleErrorMessage = {
  fallbackMessage?: string
  error:
    | {
        message: string
      }
    | string
}

export const handleErrorMessage = ({
  error,
  fallbackMessage
}: HandleErrorMessage) => {
  if (typeof error === 'string') return error

  if (error?.message) return error.message

  return fallbackMessage || 'Houve um erro'
}

//
export const formatAxiosError = (error: AxiosError) => {
  if (typeof error === 'object' && error.response) {
    const { status, data } = error.response
    const errorMessage = `HTTP Status ${status}`

    if (data && (data.message || data.messages?.[0])) {
      return `${data.message || data.messages[0]}`
    }

    return errorMessage
  }

  // Se o erro não for um objeto com uma propriedade "response", apenas retornar o erro como string.
  return error.toString()
}
