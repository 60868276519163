import React from 'react'
import { FiCheck, FiExternalLink, FiUser, FiXCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Icon } from '~/components/Icon'
import { WrapperShadow, Header, Content } from '~/components/WrapperShadow'
import { formatCPF } from '~/helpers'

/**
 *
 * @param {object} orderData ID do pedido que servirá para montar o link do checkout
 */

const PanelCustomer = () => {
  const { orderData } = useSelector(state => state.order)

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiUser} /> <strong>Dados do cliente</strong>
          </span>
        </div>
        <aside>
          <div className="tags">
            <div className="column">
              <Button
                text="Fidelidade"
                iconRight={
                  orderData.loyalty_program ? <FiCheck /> : <FiXCircle />
                }
                template={
                  orderData.loyalty_program ? 'transparentDark' : 'transparent'
                }
                as="span"
                className={`${
                  orderData.loyalty_program ? 'success' : 'danger'
                }`}
                size="xsmall"
              />
            </div>
          </div>
        </aside>
      </Header>
      <Content>
        {orderData.customer?.name}
        <br />
        {orderData.customer?.main_email}
        <br />
        <strong>Telefone</strong> {orderData?.customer?.main_phone}
        <br />
        <strong>CPF:</strong>{' '}
        {orderData.customer?.social_id &&
          formatCPF(orderData.customer.social_id)}
        <br />
        <Button
          text={'Ver cliente'}
          iconRight={<FiExternalLink />}
          template="primary"
          as="a"
          customWidth="auto"
          size="xsmall"
          href={`/customers/edit/${orderData.customer?.id}`}
          target="_blank"
        />
      </Content>
      {/* <Footer>
      <Button template="light" text="Editar" />
    </Footer> */}
    </WrapperShadow>
  )
}

export default PanelCustomer
