import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { FormGroup, Input, Label } from '~/components/unform'
import { CheckboxList } from '~/components/unform/CheckboxList'

// import { Container } from './styles';

type FormEditLinkProps = {
  handleCancel?: () => void
  options: CheckboxOptionProps[]
  initialData: {
    seller_id?: string
    type?: {
      [key: string]: boolean
    }
  }
  onSubmit: (data: unknown) => void
  fields: ('adTypes' | 'sellerId')[]
}

const FormEditLink: React.FC<FormEditLinkProps> = ({
  handleCancel,
  initialData,
  options,
  onSubmit,
  fields = ['adTypes', 'sellerId']
}) => {
  return (
    <Form onSubmit={onSubmit} initialData={initialData}>
      {fields.includes('adTypes') && (
        <FormGroup>
          <Label text={t('rm:adtype.formEditLink.form.type')} />
          <CheckboxList options={options} name="type" />
        </FormGroup>
      )}

      {fields.includes('sellerId') && (
        <FormGroup className="mt-4">
          <Label text={t('rm:adtype.formEditLink.form.sellerId')} />
          <Input name="seller_id" />
        </FormGroup>
      )}

      <FormGroup className="actionButtons">
        {handleCancel && (
          <Button
            text={t('common:actions.cancel')}
            onClick={handleCancel}
            template="transparentDanger"
            size="small"
          />
        )}

        <Button
          type="submit"
          text={t('common:actions.save')}
          textLoading={t('common:actions.saving')}
          size="small"
          template="success"
        />
      </FormGroup>
    </Form>
  )
}

export default FormEditLink
