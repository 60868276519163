import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppInfo } from '~/hooks/useAppInfo'
import { useAppSelector } from '~/store/hooks'
import { filterRoutes } from '~/store/modules/accessModules/helpers/filterRoutes'

import LanguageToggle from '../LanguageToggle'
import LogoMenu from '../LogoMenu'
import Children from './Children'
import { checkRules } from './helpers'
import SidebarItem from './SidebarItem'

const Sidebar: React.FC = () => {
  const { isWhiteLabel, headerTheme } = useAppInfo()

  const {
    sidebar,
    signin: { accountType },
    accessModules: { modules }
  } = useAppSelector(state => state)

  const menu = useMemo(() => filterRoutes({ modules, accountType }), [])

  const location = useLocation()
  const pathName = location.pathname

  return (
    <nav
      className={`sidebar${!sidebar.isOpen ? ' toggled' : ''}${
        sidebar.isSticky ? ' sidebar-sticky' : ''
      }`}
    >
      <div className="sidebar-content">
        <LogoMenu />

        <ul className="sidebar-nav">
          {menu.map((route, index) => (
            <React.Fragment key={`item-${index}`}>
              {checkRules(route) && (
                <>
                  {route.header ? (
                    <li className="sidebar-header">{route.header}</li>
                  ) : null}

                  {route?.children?.filter(item => !item?.hideMenu)?.length ? (
                    <Children route={route} pathName={pathName} />
                  ) : (
                    <SidebarItem
                      name={route.name}
                      to={route.path}
                      icon={route.icon}
                      badgeColor={route.badgeColor}
                      badgeText={route.badgeText}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>

      <footer>
        &copy; {new Date().getFullYear()} -{' '}
        <small>v{String(process.env.VERSION)}</small> -{' '}
        {isWhiteLabel ? 'by ' : ''}
        <a href="https://www.newtail.com.br" target="_blank" rel="noreferrer">
          Newtail
        </a>
      </footer>
      <footer>
        <LanguageToggle />
      </footer>
    </nav>
  )
}

export default Sidebar
