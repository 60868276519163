import { IconType } from 'react-icons'
import { FiShoppingBag as icon } from 'react-icons/fi'

import { OperatorsList, OperatorsNew, OperatorsEdit } from '~/pages/operators'

export const operators: RootMenuItem<IconType> = {
  id: '66beb61c-5b7e-4840-9326-e548849491cf',
  path: '/operators',
  name: 'Operadores',
  pageTitle: 'Operadores',
  icon,
  permissionType: 'admin',
  children: [
    {
      id: null, // rota-sem-id
      path: '/operators/new',
      name: 'Novo operador',
      pageTitle: 'Novo operador',
      component: OperatorsNew,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/operators/edit/:id',
      name: 'Editar operador',
      pageTitle: 'Editar operador',
      component: OperatorsEdit,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/operators/list/:locationId',
      name: 'Listagem de operadores',
      pageTitle: 'Listagem de operadores',
      component: OperatorsList,
      hideMenu: true
    },
    {
      id: '0515a75f-9e7e-4fe5-9e8a-7efc43146a4e',
      path: '/operators/list/',
      name: 'Listagem de operadores',
      pageTitle: 'Listagem de operadores',
      component: OperatorsList
    }
  ]
}
