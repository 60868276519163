import i18next from 'i18next'

type AdTypesOption = {
  type: CampaignType
} & RadioOptionProps<CampaignAdType>

export const adTypesOptions: AdTypesOption[] = [
  {
    id: `type_product`,
    name: `type_product`,
    value: 'product',
    label: i18next.t(`rm:productAds`),
    type: 'on_site',
    description: i18next.t('rm:adTypes.create.product.description')
  },
  {
    id: `type_banner`,
    name: `type_banner`,
    value: 'banner',
    label: i18next.t(`rm:Banner`),
    type: 'on_site',
    description: i18next.t('rm:adTypes.create.banner.description')
  },
  {
    id: `type_sponsored_brand`,
    name: `type_sponsored_brand`,
    value: 'sponsored_brand',
    label: i18next.t(`rm:sponsoredBrand`),
    type: 'on_site',
    description: i18next.t('rm:adTypes.create.sponsored_brand.description')
  },
  {
    id: `type_digital_signage`,
    name: `type_digital_signage`,
    value: 'digital_signage',
    label: i18next.t(`rm:digitalSignage`),
    type: 'in_store',
    description: i18next.t('rm:adTypes.create.digital_signage.description')
  }
]
