import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
  useLayoutEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'

import Alert from '~/components/Alert'
import Loading from '~/components/Loading'
import { getUserId } from '~/helpers'
import useHasFocus from '~/hooks/useWindowFocus'
import {
  getMessages,
  getMoreMessages,
  setNewMessageStatusToRead
} from '~/modules/chat/store/actions'

import Message from '../Message'

import { Container, DateTag, MessageGroup } from './styles'

const ChatContent = () => {
  const {
    selectedChat,
    chatIdByUrl,
    loading,
    messages,
    messagesTotal,
    messagesPages,
    messagesCurrentPage
  } = useSelector(({ chats }) => chats)
  const chatRef = useRef(null)
  const loadRef = useRef(null)

  const [page, setPage] = useState(1)
  const [internalLoading, setInternalLoading] = useState(false)

  const { chatId, session } = selectedChat
  const hasSession = useMemo(() => !!selectedChat?.session, [selectedChat])
  /**
   * Sinaliza se a conversa está finalizada
   * */
  const isFinished = useMemo(
    () => !!selectedChat.session?.finishedAt,
    [selectedChat]
  )

  const localMessages = useMemo(() => messages, [messages])

  const { unreadMessage } = selectedChat

  const dispatch = useDispatch()

  useEffect(() => {
    if (chatId === chatIdByUrl) {
      dispatch(getMessages({ id: chatId }))
      setPage(1)
    }
  }, [dispatch, chatId, chatIdByUrl])

  useEffect(() => {
    if (page === 1 && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
  }, [chatRef, messages, page])

  const userIdLogged = getUserId()

  const isStartChat = useMemo(
    () => !!session?.userId && !session?.finishedAt,
    [session?.finishedAt, session?.userId]
  )

  const isUserOnStartChat = useMemo(
    () => selectedChat?.session?.userId === userIdLogged,
    [selectedChat?.session?.userId, userIdLogged]
  )

  const handleStatusChat = useCallback(() => {
    if (isUserOnStartChat && isStartChat) {
      dispatch(setNewMessageStatusToRead({ chatId })) // atualiza que a conversa foi visualizada
    }
  }, [isUserOnStartChat, isStartChat, dispatch, chatId])

  const { focus: windowFocus } = useHasFocus()

  useEffect(() => {
    if (unreadMessage && windowFocus) {
      handleStatusChat()
    }
  }, [handleStatusChat, unreadMessage, windowFocus])

  const isLastPage = useMemo(
    () => messagesPages === page,
    [messagesPages, page]
  )

  const handleLoadMore = useCallback(() => {
    const newPage = messagesCurrentPage + 1

    setPage(newPage)

    dispatch(getMoreMessages({ id: chatId, page: newPage }))

    setInternalLoading(false)
  }, [chatId, dispatch, messagesCurrentPage])

  const handleScroll = useCallback(() => {
    const position = loadRef?.current?.getBoundingClientRect().top

    if (!isLastPage && !loading && !internalLoading && position > 0) {
      setInternalLoading(true)
      handleLoadMore()
    }
  }, [handleLoadMore, internalLoading, isLastPage, loading])

  useLayoutEffect(() => {
    const ref = chatRef.current

    if (chatRef && !isLastPage) {
      ref.addEventListener('scroll', handleScroll, {
        passive: true
      })

      return () => {
        ref.removeEventListener('scroll', handleScroll)
      }
    }
  }, [chatRef, handleScroll, isLastPage])

  const groupedMessages = useMemo(
    () =>
      localMessages.reduce((prev, curr) => {
        const date = moment(curr.createdAt).format('YYYY-MM-DD')

        return {
          ...prev,
          [date]: [...(prev[date] || []), curr]
        }
      }, {}),
    [localMessages]
  )

  return (
    <Container
      className="chat ChatContent"
      isLoading={loading}
      hasSession={hasSession}
      isFinished={isFinished}
    >
      <div className="alerts">
        {(loading || internalLoading) && (
          <Loading status={loading}>Carregando mensagens...</Loading>
        )}
      </div>

      <div className="messages" ref={chatRef}>
        {!hasSession && (
          <Alert active>
            Essa conversa está arquivada e será reativada quando o cliente
            enviar uma nova mensagem.
          </Alert>
        )}

        {Object.entries(groupedMessages).map(([date, messages]) => (
          <MessageGroup key={date}>
            {messages?.map((message, index) => (
              <Message
                data={message}
                key={message.messageId}
                lastUsername={messages[index - 1]?.userName || null}
                lastIsSent={!!messages[index - 1]?.fromStore}
              />
            ))}
            <DateTag>
              {moment(date, 'YYYY-MM-DD').calendar(null, {
                sameDay: '[Hoje]',
                lastDay: '[Ontem]',
                lastWeek: 'dddd',
                sameElse: 'LL'
              })}
            </DateTag>
          </MessageGroup>
        ))}

        <button
          type="button"
          onClick={handleLoadMore}
          ref={loadRef}
          className="loadMoreMsg"
        >
          Carregar mais... | Página: {page}/{messagesPages} - Total:{' '}
          {messagesTotal}
        </button>
      </div>
    </Container>
  )
}

export default ChatContent
