import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';

import Button from '../../../../components/Button';
import Form from '../../../../components/forms/form';
import Input from '../../../../components/forms/inputs';
import Panel from '../../../../components/Panel';

import { submitFormProfileCompany, getCompanyInfo } from '../profile-actions';

const CompanyForm = ({
  loadingGetCompany,
  loadingSaveCompany,
  company,
  getCompanyInfo,
  submitFormProfileCompany,
}) => {
  useEffect(() => {
    getCompanyInfo();
  }, []);
  const [formIsValid, setFormIsValid] = useState(false);

  return (
    <Panel title="Dados da empresa" isLoading={loadingGetCompany}>
      <Form
        onSubmit={data => submitFormProfileCompany(data)}
        checkIsValid={isValid => setFormIsValid(isValid)}
        className="form-profile__form-info"
        data-cy="form-company"
      >
        <div className="form-profile__flex">
          <FormGroup>
            <Input
              label="Nome da empresa"
              name="name"
              defaultValue={company.name}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="CNPJ"
              name="company_federal_id"
              mask="cnpj"
              defaultValue={company.company_federal_id}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Razão social"
              name="company_name"
              defaultValue={company.company_name}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Inscrição estadual"
              name="company_state_id"
              defaultValue={company.company_state_id}
            />
          </FormGroup>
        </div>
        <h4 className="form-profile__subtitle">Endereço da empresa</h4>
        <div className="form-profile__flex">
          <FormGroup>
            <Input
              label="CEP"
              name="address_postal_code"
              mask="cep"
              defaultValue={company.address_postal_code}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Rua"
              name="address_street"
              defaultValue={company.address_street}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Número"
              name="address_number"
              type="number"
              defaultValue={company.address_number}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Complemento"
              name="address_complement"
              defaultValue={company.address_complement}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Bairro"
              name="address_neighborhood"
              defaultValue={company.address_neighborhood}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Cidade"
              name="address_city"
              defaultValue={company.address_city}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Estado"
              name="address_state"
              defaultValue={company.address_state}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="País"
              name="address_country"
              defaultValue={company.address_country}
            />
          </FormGroup>
        </div>

        <div className="form-profile__button-save">
          <Button
            type="submit"
            color="primary"
            size="lg"
            text="Salvar"
            loading={loadingSaveCompany}
            spinner={{ type: 'grow', size: 'sm', color: 'secondary' }}
            disabled={!formIsValid}
            data-cy="button-update-company"
          />
        </div>
      </Form>
    </Panel>
  );
};

export default connect(
  state => ({
    company: state.profile.company,
    loadingSaveCompany: state.profile.loadingSaveCompany,
    loadingGetCompany: state.profile.loadingGetCompany,
  }),
  { submitFormProfileCompany, getCompanyInfo },
)(CompanyForm);
