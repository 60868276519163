import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes as Switch } from 'react-router-dom'

import { SidePanelProvider } from '~/hooks/useSidePanel'
import { useUI } from '~/hooks/useUi'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import MasterLayout from '~/layouts/master-layout'
import NoAuthLayout from '~/layouts/no-auth-layout'
import Logout from '~/modules/auth/pages/login/logout'
import MissingHome from '~/modules/auth/pages/MissingHome'
import { useAppSelector } from '~/store/hooks'
import { getAccessModules } from '~/store/modules/accessModules/actions'
import { filterRoutes } from '~/store/modules/accessModules/helpers/filterRoutes'

import ScrollToTop from '../components/ScrollToTop'
import { history } from '../helpers'
import Page404 from '../modules/auth/pages/page-404'
import childRoutes from './elements/childRoutes'
import CustomRouter from './elements/CustomRouter'
import PrivateRoute from './elements/private-route'
import * as allRoutes from './index'

const masterLoadingKeyGetAccessModules = 'getAccessModules'

const Routes = () => {
  const { t } = useAPPTranslation()
  const dispatch = useDispatch()

  const {
    modules,
    modulesAccountId,
    error,
    loading: loadingModules
  } = useAppSelector(state => state.accessModules)
  const { accountType, accountId } = useAppSelector(state => state.signin)

  const { addMasterLoading, removeMasterLoading, messagesMasterLoading } =
    useUI()

  const filteredRoutes = useMemo(
    () => filterRoutes({ modules, accountType }),
    [modules, accountType]
  )

  /**
   * Checando se carregamos os módulos
   */

  useEffect(() => {
    if (!!accountId && (!modules || accountId !== modulesAccountId)) {
      addMasterLoading({
        id: masterLoadingKeyGetAccessModules,
        message: t('c:messages.configuringPlatform')
      })

      dispatch(getAccessModules())
    }
  }, [accountId, addMasterLoading, dispatch, modules, modulesAccountId, t])

  const hasMasterLoading = useMemo(
    () =>
      messagesMasterLoading.findIndex(
        item => item.id === masterLoadingKeyGetAccessModules
      ) > -1,
    [messagesMasterLoading]
  )

  useEffect(() => {
    if (
      (hasMasterLoading && modules && accountId === modulesAccountId) ||
      !!error
    ) {
      removeMasterLoading(masterLoadingKeyGetAccessModules)
    }
  }, [
    error,
    accountId,
    hasMasterLoading,
    modules,
    modulesAccountId,
    removeMasterLoading
  ])

  return (
    <CustomRouter history={history}>
      <SidePanelProvider>
        <ScrollToTop location="top">
          <Switch>
            {!messagesMasterLoading.length && (
              <>
                <Route path="/logout" element={<Logout />} />

                {childRoutes(NoAuthLayout, allRoutes.publicRoutes, false)}

                {!!modules?.length && (
                  <>
                    {childRoutes(MasterLayout, filteredRoutes, true)}

                    {/* {childRoutes(MasterLayout, routerWithValidation, true)} */}

                    <Route path="/" element={<MissingHome />} />

                    <Route
                      path="*"
                      element={
                        <PrivateRoute isPrivate={true}>
                          <MasterLayout>
                            <Page404 />
                          </MasterLayout>
                        </PrivateRoute>
                      }
                    />
                  </>
                )}

                <Route
                  path="*"
                  element={
                    <PrivateRoute isPrivate={true}>
                      <MasterLayout>
                        <Page404 />
                      </MasterLayout>
                    </PrivateRoute>
                  }
                />
              </>
            )}
          </Switch>
        </ScrollToTop>
      </SidePanelProvider>
    </CustomRouter>
  )
}

export default Routes
