import { jwtDecode } from 'jwt-decode'

import localstorageConstants, {
  getLocalstorage,
  removeLocalstorage
} from './localstorage-manipulation'

export type JWTPayload = {
  user_id: string
  email: string
  name: string
  current_account_id: string
  current_role: UserRoles
  current_account_type: AccountTypes
  publisher_id?: string
  is_network?: string
  iat: number
  exp: number
}

export const getAuthToken = () => getLocalstorage(localstorageConstants.TOKEN)

export const getAppInfo = () => {
  const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

  if (storageValue) {
    return JSON.parse(storageValue) as AppInfoData
  }

  return null
}

type GetDecodedToken = () => JWTPayload | Record<string, never>

export const getDecodedToken: GetDecodedToken = () => {
  const token = getAuthToken()

  if (token) {
    return jwtDecode<JWTPayload>(token)
  }
  return {}
}

export const removeAuthToken = () =>
  removeLocalstorage(localstorageConstants.TOKEN)

export const getAccountId = () => getDecodedToken()?.current_account_id
export const getIsWhiteLabel = () => !!getDecodedToken()?.publisher_id
export const getIsNetwork = () => !!getDecodedToken()?.is_network
export const getWhiteLabelPublisherId = () => getDecodedToken()?.publisher_id

export const getAccountType = () =>
  (getDecodedToken()?.current_account_type as 'industry' | 'grocery') ||
  'grocery'

export const getUserId = () => getDecodedToken().user_id

export const getRole = () => getDecodedToken().current_role

export const getEmail = () => getDecodedToken().email

/**
 * Retail media
 */
export const getRetailMediaAccountType = () => {
  const accountType = getAccountType()

  type TypesAccount = {
    [key in AccountTypes]: RetailMediaAccountTypes
  }

  const types: TypesAccount = {
    grocery: 'publisher',
    industry: 'advertiser'
  }

  return types[accountType] as RetailMediaAccountTypes
}

export const isAdvertiser = getRetailMediaAccountType() === 'advertiser'
export const isPublisher = getRetailMediaAccountType() === 'publisher'

// Quando estamos logado numa conta, costumamos usar muito o id ou dados do
// publisher ou do advertiser nas requisições. Para facilitar os
// componentes agnósticos, criamos esses helpers
export const requestRetailMediaAccountID =
  getRetailMediaAccountType() === 'publisher' ? 'advertiser_id' : 'publisher_id'

export const requestRetailMediaAccount =
  getRetailMediaAccountType() === 'publisher' ? 'advertiser' : 'publisher'
