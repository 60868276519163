import { useCallback, useState } from 'react'
import { FiArrowDown, FiArrowUp, FiChevronRight } from 'react-icons/fi'
import { RiWallet3Line } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { InputMoney } from '~/components/unform'
import { formatMoneyV2, getRetailMediaAccountType } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useModal from '~/hooks/useModal'

import { CreateTransaction } from '../CreateTransaction'

import * as S from './styles'

export const HeaderWalletBalance = ({
  balance = 0,
  debit,
  credit,
  initialLoading
}: {
  initialLoading: boolean
  balance: number
  debit: number
  credit: number
}) => {
  const [initialData, setInitialData] = useState({ amount: '' })

  const { id: checkingAccountId } = useParams()

  const [Modal, showModal, toggle] = useModal()

  const isAdvertiser = getRetailMediaAccountType() === 'advertiser'
  const isPublisher = getRetailMediaAccountType() === 'publisher'

  const { hasRecharge, rechargeEmail } = useAppInfo()

  /**
   * Submit antes de abrir o modal
   */

  const handleSubmitTransactionInitiationGrocery = useCallback(
    (data: { amount: InputMoneyValues }) => {
      if (isPublisher) {
        setInitialData({ amount: data.amount.numAsString })
      }

      toggle()
    },
    [isPublisher, toggle]
  )

  return (
    <>
      <S.Container>
        <S.BalanceWrapper>
          <header>
            <h4>{t('rm:Balance')}</h4>
          </header>

          <div className="balance">
            <RiWallet3Line />
            <span>
              {initialLoading
                ? t('common:actions.loading')
                : formatMoneyV2({ value: balance || 0 })}
            </span>
          </div>

          <Loading onlyLine status={initialLoading} />
        </S.BalanceWrapper>

        {isAdvertiser && !hasRecharge && (
          <S.RechargeWrapper>
            <header>
              <h4>{t('rm:wallet.table.actions.addCredit')}</h4>
            </header>

            <S.TextRecharge>
              {t('rm:recharge.howto')}{' '}
              <a href={`mailto:${rechargeEmail}`}>{rechargeEmail}</a>.
            </S.TextRecharge>
          </S.RechargeWrapper>
        )}

        {isPublisher && (
          <S.RechargeWrapper>
            <header>
              <h4>{t('rm:wallet.table.actions.addCredit')}</h4>
            </header>

            <Form onSubmit={handleSubmitTransactionInitiationGrocery}>
              <InputMoney
                name="amount"
                placeholder={formatMoneyV2({ value: 0 })}
              />

              <Button
                iconRight={<FiChevronRight />}
                type="submit"
                text={t('common:actions.add')}
              />
            </Form>
          </S.RechargeWrapper>
        )}

        <S.LastTransactions>
          <S.DebitWrapper>
            <FiArrowDown className="icon" />

            <div className="text-value">
              <span className="title">{t('rm:debits')}</span>
              <small className="subtitle">{t('rm:last7days')}</small>
              <span className={debit <= 0 ? 'balance' : 'not-balance'}>
                {debit <= 0
                  ? formatMoneyV2({ value: debit || 0 })
                  : t('rm:noTransactions')}
              </span>
            </div>
          </S.DebitWrapper>

          <S.CreditWrapper>
            <FiArrowUp className="icon" />

            <div className="text-value">
              <span className="title">{t('rm:credits')}</span>
              <small className="subtitle">{t('rm:last7days')}</small>
              <span className={credit > 0 ? 'balance' : 'not-balance'}>
                {credit > 0
                  ? formatMoneyV2({ value: credit || 0 })
                  : t('rm:noTransactions')}
              </span>
            </div>
          </S.CreditWrapper>
        </S.LastTransactions>
      </S.Container>

      {/*  */}

      <Modal
        title={t('rm:addCreditsWallet')}
        onClose={toggle}
        isOpen={!!showModal}
        maxWidth="100%"
      >
        {isPublisher && (
          <CreateTransaction
            checkingAccountId={checkingAccountId}
            currentBalance={balance}
            parentInitialData={initialData}
            onClose={toggle}
          />
        )}
      </Modal>
    </>
  )
}
