import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/report/v2`

/**
 * Get spot reports */
export const getCampaignsReportKey = 'getCampaignsReport'
export const getCampaignsReportServiceURL = `${baseURL}/campaigns`
export const getCampaignsReportService = (
  params: GetCampaignReportRequestParams
) =>
  axios.get<GetCampaignsReportResponse>(`${baseURL}/campaigns`, {
    params
  })

/**
 * Get history reports */
export const getCampaignsReportHistoryKey = 'getCampaignsReportHistory'
export const getCampaignHistoryReportService = (
  params: GetCampaignReportRequestParams
) =>
  axios.get<GetCampaignHistoryReportResponse>(`${baseURL}/campaigns_history`, {
    params
  })

/**
 * Get external report */
export const getExternalDashboardService: GetExternalDashboardService = ({
  queries,
  dashboardName
}) =>
  axios.post(`${baseAPI}/report/dashboards/internal/${dashboardName}`, queries)
