import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import authEN from '~/modules/auth/i18n/en/translation.json'
import authES from '~/modules/auth/i18n/es/translation.json'
import authPTBR from '~/modules/auth/i18n/pt-br/translation.json'
import retailMediaEN from '~/modules/retailMedia/i18n/en/translation.json'
import retailMediaES from '~/modules/retailMedia/i18n/es/translation.json'
import retailMediaPTBR from '~/modules/retailMedia/i18n/pt-br/translation.json'
import usersEN from '~/modules/users/i18n/en/translation.json'
import usersES from '~/modules/users/i18n/es/translation.json'
import usersPTBR from '~/modules/users/i18n/pt-br/translation.json'
import configEN from '~/pages/configurations/i18n/en/translation.json'
import configES from '~/pages/configurations/i18n/es/translation.json'
import configPTBR from '~/pages/configurations/i18n/pt-br/translation.json'

import commonEN from './locales/en/common.json'
import commonES from './locales/es/common.json'
import commonPTBR from './locales/pt-br/common.json'

export const defaultNS = 'retailMedia'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt-BR',
    debug: false,
    resources: {
      'pt-BR': {
        rm: retailMediaPTBR,
        auth: authPTBR,
        users: usersPTBR,
        common: commonPTBR,
        c: commonPTBR,
        config: configPTBR
      },
      en: {
        rm: retailMediaEN,
        auth: authEN,
        users: usersEN,
        common: commonEN,
        c: commonEN,
        config: configEN
      },
      'es-AR': {
        rm: retailMediaES,
        auth: authES,
        users: usersES,
        common: commonES,
        c: commonES,
        config: configES
      },
      'es-CO': {
        rm: retailMediaES,
        auth: authES,
        users: usersES,
        common: commonES,
        c: commonES,
        config: configES
      },
      es: {
        rm: retailMediaES,
        auth: authES,
        users: usersES,
        common: commonES,
        c: commonES,
        config: configES
      }
    },
    ns: ['common', 'c', 'rm', 'auth', 'users', 'config'],
    defaultNS,
    interpolation: {
      escapeValue: false,
      format: function (
        value: string,
        format: 'uppercase' | 'lowercase' | 'capitalize'
      ) {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'lowercase') return value.toLowerCase()
        if (format === 'capitalize')
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`
        return value
      }
    }
  })

export default i18next
