import { IconType } from 'react-icons'
import { FiUserCheck as icon } from 'react-icons/fi'

import i18n from '~/i18n'
import accessControl from '~/utils/access-control'

import { UserEdit, UsersList, UserNew } from './pages'

export const users: RootMenuItem<IconType> = {
  id: 'b81df401-b12c-43eb-9384-01381756fd77',
  path: '/users/list',
  name: i18n.t('users:users'),
  pageTitle: i18n.t('users:users'),
  icon,
  permissionType: 'admin',
  withSeparator: true,
  containsHome: true,
  children: [
    {
      id: '3c130900-efb2-4f4e-9a2d-022768c76beb',
      path: '/users/list',
      name: i18n.t('users:usersList'),
      pageTitle: i18n.t('users:users'),
      component: () =>
        accessControl({ role: 'admin', ComposedComponent: UsersList }),
      permissionType: 'admin',
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/users/new',
      name: i18n.t('users:createUser'),
      pageTitle: i18n.t('users:createUser'),
      component: () =>
        accessControl({ role: 'admin', ComposedComponent: UserNew }),
      permissionType: 'admin',
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/users/edit/:id',
      name: i18n.t('users:editUser'),
      pageTitle: i18n.t('users:editUser'),
      component: () =>
        accessControl({ role: 'admin', ComposedComponent: UserEdit }),
      permissionType: 'admin',
      hideMenu: true
    }
  ]
}
