import { useState } from 'react'

import { FormCreateTransaction } from './Form'
import { SuccessCreateTransaction } from './Success'

import * as S from './styles'

export const CreateTransaction = ({
  parentInitialData,
  currentBalance,
  checkingAccountId,
  onClose
}: FormCreateTransactionProps) => {
  const [success, setSuccess] = useState<{ newBalance: string } | null>(null)

  return (
    <S.Container>
      {!success && (
        <FormCreateTransaction
          parentInitialData={parentInitialData}
          currentBalance={currentBalance}
          checkingAccountId={checkingAccountId}
          onSuccess={setSuccess}
          onClose={onClose}
        />
      )}

      {success && (
        <SuccessCreateTransaction
          currentBalance={success?.newBalance}
          onClose={onClose}
        />
      )}
    </S.Container>
  )
}
