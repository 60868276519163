import { useMemo } from 'react'

import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import ToggleInput from '~/components/Toggle'
import { useAlert } from '~/hooks/useAlert'
import ExportButton from '~/modules/retailMedia/components/ExportButton'
import { Footer } from '~/pages/products/list/styles'
import { useAppSelector } from '~/store/hooks'

import AddAdWrapper from './AddAdWrapper'
import AdsTableFilters from './Filters'
import { useAdsTable } from './hooks/useAdsTable'

const AdsTable = () => {
  const {
    data,
    error,
    isValidating,
    innerLoading,
    page,
    perPage,
    total,
    handleActive,
    handlePagination,
    handleItemsPerPageChange,
    sortDirection,
    sortKey,
    handleSortList,
    schema,
    queryParams,
    showInactive,
    insideCampaign
  } = useAdsTable()

  const {
    campaign: {
      item: { data: campaignData }
    }
  } = useAppSelector(state => state.retailMedia)

  const [AlertOnError] = useAlert(error)

  const isSingleAdCampaign = useMemo(
    () => campaignData?.adType === 'sponsored_brand',
    [campaignData?.adType]
  )

  return (
    <>
      <HeaderPages
        title={t('rm:AdsList')}
        sideLeftComponent={
          isSingleAdCampaign ? null : (
            <ToggleInput
              name="show_inactive"
              label={t('rm:ShowInactive')}
              isChecked={showInactive}
              size="small"
              onChange={handleActive}
            />
          )
        }
        sideRightComponent={
          <nav className="d-flex gap-2">
            {campaignData?.canEditCampaign && <AddAdWrapper />}
            <ExportButton queryParams={queryParams} exportType={'adsList'} />
          </nav>
        }
      />

      <AdsTableFilters adType={campaignData?.adType} />

      <Loading status={!data && isValidating}>
        {t('common:actions.loadingData')}...
      </Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      <Panel noPaddingBody isLoading={!data}>
        <Loading status={innerLoading} onlyLine />

        {!!data && (
          <Table
            schema={schema}
            data={data}
            total={total}
            sideBorder
            smallerRowPadding
            activeSortDirection={sortDirection}
            activeSortKey={sortKey}
            onSort={handleSortList}
            freeze="first-row-and-column"
          />
        )}

        {total === 0 && data && (
          <AlertInfo
            template="warning"
            text={t('common:table.emptyFiltered')}
          />
        )}

        {insideCampaign && (
          <Footer>
            <ListPagination
              total={total}
              label={total > 1 ? t('rm:ads') : t('rm:ad')}
              currentPage={page}
              itemsPerPage={perPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </Footer>
        )}
      </Panel>

      {!insideCampaign && (
        <Footer>
          <ListPagination
            total={total}
            label={total > 1 ? t('rm:ads') : t('rm:ad')}
            currentPage={page}
            itemsPerPage={perPage}
            menuPlacement="top"
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </Footer>
      )}
    </>
  )
}

export default AdsTable
