import { useCallback, useMemo, useRef, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import {
  createCampaignKey,
  createCampaignService
} from '~/modules/retailMedia/services/campaign'
import { useAppSelector } from '~/store/hooks'

import ProductsPanel from '../../../ProductsPanel'
import AdvancedSettings from '../../elements/Advanced'
import Budget from '../../elements/Budget'
import CampaignSettings from '../../elements/CampaignSettings'
import CampaignTargeting from '../../elements/CampaignTargeting'
import SponsoredBrand from '../../elements/SponsoredBrand'
import * as S from '../../styles'
import { DTOCreateSponsoredBrandsCampaign } from './dto'

const SponsoredBrands: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const [loading, setLoading] = useState(false)

  const [targetingContentIsValid, setTargetingContentIsValid] = useState(false)
  const [campaignTargeting, setCampaignTargeting] = useState<
    | undefined
    | {
        targeting: RetailMediaCampaignSegmentation
        data: { keywords: string[] | null; categories: Tag[] | null }
      }
  >()

  const [budgetValueIsValid, setBudgetValueIsValid] = useState(false)

  const rmid = useQueryParams().get('rmid')

  const formDataFinal = useMemo(() => {
    const getTargeting = () => {
      if (campaignTargeting?.targeting === 'category') {
        return {
          categories: String(
            campaignTargeting?.data?.categories.map(item => item.id)
          )
        }
      }

      if (campaignTargeting?.targeting === 'keyword') {
        return {
          keywords: String(campaignTargeting?.data?.keywords)
        }
      }

      return {}
    }

    return {
      name: 'name',
      start_date: new Date(),
      type: 'on_site',
      targeting: getTargeting()
    }
  }, [campaignTargeting])

  /**
   * Check data validity
   */
  const formIsValid = useMemo(() => {
    const messages = [].filter(item => !!item)

    return {
      isValid: true,
      messages
    }
  }, [])

  /**
   * Form is ready
   * Valor para controlarmos se devemos bloquear o submit
   */
  const formIsReadyToSubmit = useMemo(
    () => budgetValueIsValid,
    [budgetValueIsValid]
  )

  /**
   * Handle products
   */
  const {
    campaign: {
      products: { selectecProducts }
    }
  } = useAppSelector(state => state.retailMedia)

  /**
   * Handle request
   */
  const createCampaign = useMutation({
    mutationKey: [createCampaignKey],
    mutationFn: createCampaignService,
    onSuccess: response => {
      setLoading(false)

      history.push(`/retail-media/campaign/view/${response.data.id}`)
      toastr.success(t('rm:success'), t('rm:message.created.campaign.success'))
    },
    onError: () => {
      setLoading(false)

      toastr.error(
        t('rm:campaign.create.toast.error.title'),
        t('rm:message.created.campaign.error')
      )
    }
  })

  /**
   * Handle submit
   */
  const { lengthCentsCurrency } = useAppInfo()

  const handleSubmit = useCallback(
    async (formData: CreateSponsoredBrandsCampaignFormData) => {
      const products = selectecProducts?.map(item => ({
        id: item?.id,
        sku: item?.product_sku || item?.sku
      }))

      const preData = {
        ...formData,
        products,
        publisher_id: rmid,
        lengthCentsCurrency
      }

      const body = DTOCreateSponsoredBrandsCampaign(preData)

      setLoading(true)

      await createCampaign.mutateAsync(body)
    },
    [createCampaign, lengthCentsCurrency, rmid, selectecProducts]
  )

  /**
   * Initial data
   */
  const initialData: Partial<CreateSponsoredBrandsCampaignFormData> = {
    budget: { daily: null },
    placements: { placementsCheck: 'all' },
    settings: {
      budget_type: 'fast_as_possible'
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
      <NameAndTimeInterval
        formRef={formRef}
        labelTitle={t('rm:campaign.create.NameAndTimeInterval.labelTitle')}
        placeholderTitle={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderTitle'
        )}
        hasDescription
        labelDescription={t(
          'rm:campaign.create.NameAndTimeInterval.labelDescription'
        )}
        placeholderDescription={t(
          'rm:campaign.create.NameAndTimeInterval.placeholderDescription'
        )}
        onlyDates
        endDateIsRequired={false}
        showValidBadgeOnSection
        intervalAlertMessage={[
          t('rm:campaign.create.NameAndTimeInterval.intervalAlertMessage')
        ]}
        showEndDateOptional
        titlePanel={t('rm:campaign.create.NameAndTimeInterval.titlePanel')}
      />

      <Scope path="targeting">
        <CampaignTargeting
          handleSectionValidity={setTargetingContentIsValid}
          onChange={setCampaignTargeting}
          formRef={formRef}
        />
      </Scope>

      {targetingContentIsValid && (
        <>
          <SponsoredBrand
            formRef={formRef}
            initialData={{ publisher_id: rmid }}
          />

          <CampaignSettings />

          <ProductsPanel
            formRef={formRef}
            description={t('rm:campaign.create.ProductsPanel.description')}
            initialData={{ publisher_id: rmid }}
            isRequired
          />

          {!!formIsValid.messages?.length && (
            <S.WrapperAlerts>
              {formIsValid.messages.map((message, index) => (
                <AlertInfo
                  text={message?.text || message}
                  key={index}
                  onClick={message?.action}
                  role={message?.action ? 'button' : 'text'}
                />
              ))}
            </S.WrapperAlerts>
          )}

          <Budget
            adType="sponsored_brand"
            publisherId={rmid}
            formData={formDataFinal}
            formIsValid={formIsValid}
            handleIsValid={setBudgetValueIsValid}
            initialValueDailyBudget={'R$ 100.00'}
          />

          <hr />

          <AdvancedSettings targeting={campaignTargeting.targeting} />

          <div className="form-default__buttons">
            <Button
              type="submit"
              text={t('rm:sendForApproval')}
              loading={loading}
              size="large"
              template="success"
              disabled={!formIsReadyToSubmit}
            />
          </div>
        </>
      )}
    </Form>
  )
}

export default SponsoredBrands
