import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getAuthToken, history } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

const MissingHome = () => {
  const { menu } = useAppSelector(state => state.accessModules)

  const firstUrlPossible = useMemo(() => {
    const firstModule = menu?.[0]

    if (!firstModule) return '/not-found'

    const rootHasComponent = !!firstModule?.component
    const homeURL = rootHasComponent
      ? firstModule.path
      : firstModule.children?.[0]?.path

    return homeURL
  }, [menu])

  useEffect(() => {
    const token = getAuthToken()
    if (!token) {
      history.push('/login')
    }
  }, [])

  /**
   *
   */

  const location = useLocation()
  const pathName = location.pathname

  useEffect(() => {
    if (pathName === '/') {
      history.replace(firstUrlPossible)
    }
  }, [firstUrlPossible, pathName])

  return <></>
}

export default MissingHome
