import React, { useCallback, useMemo, useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap'

import PropTypes from 'prop-types'
import { t } from 'i18next'

import { getWhiteLabelPublisherId, isPublisher } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { signOut } from '~/modules/auth/store/login/actions'

import { ButtonCopyToClipboard } from '../Buttons'
import ChangeAccount from '../ChangeAccount'
import PopoverSupport from './elements/PopoverSupport'
import NavbarUserAvatar from './NavbarUserAvatar'

import * as S from './styles'

const NavbarComponent = ({ intl, ...rest }) => {
  const { isWhiteLabel, headerTheme } = useAppInfo()

  const dispatch = useDispatch()

  const onLogout = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const publisherIdByToken = useMemo(() => getWhiteLabelPublisherId(), [])

  return (
    <S.MainHeader className="main-header">
      <div className="pageTitle">
        <h1>{rest.pageTitle}</h1>
      </div>

      <aside>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" end>
          <NavbarUserAvatar />

          <DropdownMenu right>
            <ChangeAccount />

            {isWhiteLabel && isPublisher && publisherIdByToken && (
              <DropdownItem>
                <ButtonCopyToClipboard
                  textBefore={t('config:changeAccount.menu.copyPublisherId')}
                  textAfter={t('config:changeAccount.menu.copiedPublisherId')}
                  toCopy={publisherIdByToken}
                  size="xsmall"
                  template="transparentDark"
                />
              </DropdownItem>
            )}

            <DropdownItem onClick={onLogout}>
              <strong>
                <FiLogOut />{' '}
                {t('config:changeAccount.menu.logout')}
                <small> | v{String(process.env.VERSION)}</small>
              </strong>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <PopoverSupport />
      </aside>
    </S.MainHeader>
  )
}

export default injectIntl(NavbarComponent)

NavbarComponent.propTypes = {
  intl: PropTypes.shape({})
}

NavbarComponent.defaultProps = {
  intl: null
}
