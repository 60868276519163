import { useDispatch } from 'react-redux';

import { formatStringDateToTimezone } from '~/helpers';
import {
  deletePromotion,
  updatePromotion,
} from '~/modules/promotional/store/promotion/actions';

const usePromotion = () => {
  const dispatch = useDispatch();

  const handleCancelPromotion = promotion => {
    const requestData = {
      formData: { ...promotion, active: false },
      id: promotion.id,
      successFeedbackMsg: 'Promoção cancelada com sucesso.',
      useModel: false,
    };

    dispatch(updatePromotion(requestData));
  };

  const handleFinishPromotion = ({ promotion, onSuccess }) => {
    const requestData = {
      formData: {
        ...promotion,
        end_promotion: formatStringDateToTimezone(new Date()),
      },
      id: promotion.id,
      successFeedbackMsg: 'Promoção finalizada com sucesso.',
      useModel: false,
      onSuccess,
    };

    dispatch(updatePromotion(requestData));
  };

  const handlePausePromotion = ({ promotion, onSuccess }) => {
    const requestData = {
      formData: { ...promotion, active: false },
      id: promotion.id,
      successFeedbackMsg: 'Promoção pausada com sucesso.',
      onSuccess,
      useModel: false,
    };

    dispatch(updatePromotion(requestData));
  };

  const handleReactivePromotion = ({ promotion }) => {
    const requestData = {
      formData: { ...promotion, active: true },
      id: promotion.id,
      successFeedbackMsg: 'Promoção reativada com sucesso.',
      useModel: false,
      onSuccess: () => null,
    };

    dispatch(updatePromotion(requestData));
  };

  const handleDeletePromotion = ({ promotion, onSuccess }) => {
    dispatch(deletePromotion({ id: promotion.id, onSuccess }));
  };

  return {
    handleCancelPromotion,
    handleFinishPromotion,
    handlePausePromotion,
    handleReactivePromotion,
    handleDeletePromotion,
  };
};

export default usePromotion;
