import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import UsersTable from '../../components/UsersTable'
import UsersListFilters from './Filters'
import { UserListProvider } from './hooks/useUsersList'

const UsersList = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title={t('users:usersList.pageTitle')} />

    <HeaderPages
      title={t('users:users')}
      sideRightComponent={
        <ButtonNavLink
          template="primary"
          text={t('users:addUsers')}
          to="/users/new"
          iconLeft={<FiPlus />}
          size="small"
        />
      }
    />

    <UsersListFilters />

    <UserListProvider>
      <UsersTable />
    </UserListProvider>
  </>
)

export default UsersList
