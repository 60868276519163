import React from 'react'

import * as S from './styles'

type BadgeProps = {
  iconLeft?: React.ReactNode
  children: React.ReactNode
  template?: BadgeTemplates
  className?: string
  size?: 'small' | 'medium' | 'large'
  style?: { [key in string]: string }
}

const Badge = ({
  iconLeft,
  children,
  template,
  size = 'medium',
  className = '',
  ...rest
}: BadgeProps) => {
  return (
    <S.Badge
      className={`Badge_UI_Component ${className} Badge_UI_Component_${size}`}
      template={template}
      {...rest}
    >
      {iconLeft && <div className="icon__left">{iconLeft}</div>}
      {children}
    </S.Badge>
  )
}

export { Badge }
