import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

/**
 * VIEW
 */
export const get = ({ campaign_id: id, ...rest }) => {
  return axios.get<IndustryCampaignViewResponse>(`${baseURL}/${id}`, {
    params: { ...rest }
  })
}

/**
 * CREATE
 */
export const create = body =>
  axios.post<ResponseIndustryCreateCampaign>(`${baseURL}`, body)

/**

/**
 * PATCH
 */
type BodyProps = {
  active?: boolean
  ads?: any
  name: string
  description?: string
  start_at: string
  end_date?: null | string
}

type MyPartial<T> = {
  [P in keyof T]?: T[P]
}

type PatchProps = {
  id: string
  body: MyPartial<BodyProps>
}

export const patch = ({ body, id }: PatchProps) =>
  axios.patch(`${baseURL}/${id}`, body)

/**
 * EDIT
 */
export const update = (body: any) => console.log('update', body)
// export const update = ({ id, body }) => {
//   return axios.put(`${baseURL}/${id}`, body)
// }

/**
 * DELETE
 */
export const remove = (id: string) => console.log('remove', id)
// export const remove = id => {
//   return axios.delete(`${baseURL}/${id}`)
// }

/**
 * UPLOAD
 */
export const upload = ({ body, axiosOptions }) => {
  return axios.post<{ url: string }>(`${baseAPI}/ad/upload`, body, axiosOptions)
}
/**
 * UPLOAD VIDEO
 */
export const uploadVideo = ({ body, axiosOptions }) => {
  return axios.post<{ url: string }>(
    `${baseAPI}/ad/upload/video`,
    body,
    axiosOptions
  )
}

/**
 * Upload que não comprime
 */
export const uploadLogo = ({ body, axiosOptions }) => {
  return axios.post<{ url: string }>(
    `${baseAPI}/campaign/upload-logo`,
    body,
    axiosOptions
  )
}

export const searchOffersService = body =>
  axios.post(`${baseAPI}/product/search`, body)

export default {
  get,
  patch,
  create,
  update,
  remove,
  upload,
  uploadLogo,
  uploadVideo,
  searchOffersService
}
