import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import {
  FormGroup,
  InputMoney,
  Label,
  Radio,
  Textarea
} from '~/components/unform'
import { formatMoney, unformatMoney } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useAppSelector } from '~/store/hooks'

import { createTransaction } from '../../store/grocery/wallet/actions'

export const FormCreateTransaction = ({
  parentInitialData,
  currentBalance,
  checkingAccountId,
  onClose,
  onSuccess
}: {
  onSuccess(data: { newBalance: string }): void
} & FormCreateTransactionProps) => {
  const [initialData, setInitialData] = useState<{ amount: string }>(
    parentInitialData
  )

  /**
   * Redux
   */
  const { loading, error } = useAppSelector(
    ({ retailMedia }) => retailMedia.grocery.wallet.createTransaction
  )

  const dispatch = useDispatch()

  const [AlertOnError] = useAlert(error)

  const balanceAfter = useMemo(() => {
    const amount = unformatMoney(initialData?.amount || 0)
    const sum = Number(currentBalance) + Number(amount)

    return formatMoney(sum)
  }, [currentBalance, initialData])

  /**
   * Handle para mudar o valor após a recarga
   */
  const handeChangeAmount = useCallback((data: InputMoneyValuesOnChange) => {
    const newValue = unformatMoney(data.formattedValue)

    setInitialData({ amount: String(newValue) })
  }, [])

  /**
   * Handle submit success
   */

  const handleSubmitSucess = useCallback(
    (data: CreateTransactionResponse) => {
      onSuccess({ newBalance: balanceAfter })
    },
    [balanceAfter, onSuccess]
  )

  /**
   * Handle submit
   */
  const valueIsValid = useMemo(
    () => Number(initialData?.amount || 0) > 0,
    [initialData?.amount]
  )

  const handleSubmit = useCallback(
    (data: FormCreateTransactionSubmitData) => {
      const {
        transaction_subtype,
        amount: { value },
        note
      } = data

      if (!valueIsValid) return

      const amount = unformatMoney(value)

      const body: CreateTransactionBodyRequest = {
        transaction_type: 'credit',
        transaction_subtype,
        amount,
        note
      }

      dispatch(
        createTransaction({
          values: { balanceAfter: String(unformatMoney(balanceAfter)) },
          body,
          checkingAccountId,
          onSuccess: handleSubmitSucess
        })
      )
    },
    [
      balanceAfter,
      checkingAccountId,
      dispatch,
      handleSubmitSucess,
      valueIsValid
    ]
  )

  /**
   * Formatando initial data
   */

  const formattedInitialData = useMemo(
    () => ({
      amount: initialData?.amount || '',
      transaction_subtype: 'common'
    }),
    [initialData?.amount]
  )

  return (
    <Form onSubmit={handleSubmit} initialData={formattedInitialData}>
      <FormGroup>
        <Label text={t('rm:rechargeValue')} />
        <InputMoney
          name="amount"
          helpText={`${t('rm:balanceAfterRecharge')}: ${balanceAfter}`}
          onChange={handeChangeAmount}
          required
          showWarningOnRequired
        />
      </FormGroup>

      <FormGroup>
        <Label text={t('rm:creditType')} />
        <Radio
          itemSize="small"
          options={[
            { label: t('rm:Agreement'), value: 'common' },
            { label: t('rm:bonus'), value: 'bonus' }
          ]}
          inline
          name="transaction_subtype"
        />
      </FormGroup>

      <FormGroup>
        <Label
          text={t('rm:Note')}
          helperText={t('rm:transaction.noteHelper')}
        />
        <Textarea name="note" />
      </FormGroup>

      <Loading status={loading}>{t('rm:addingCredits')}...</Loading>

      <AlertOnError />

      {!loading && (
        <FormGroup className="actionButtons">
          <Button
            text={t('common:actions.cancel')}
            template="transparent"
            onClick={onClose}
          />
          <Button
            text={t('common:actions.add')}
            type="submit"
            disabled={!valueIsValid}
          />
        </FormGroup>
      )}
    </Form>
  )
}
