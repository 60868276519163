/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useCallback, useMemo, useRef } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from '~/components/Spinner'
import { getDecodedToken } from '~/helpers'
import { useUI } from '~/hooks/useUi'
import { gtagEvent } from '~/lib/gtag'
import { setDefaultUserAccounts } from '~/modules/users/store/actions'
import { UserAccount } from '~/services/user-service'
import { changeAccount } from '~/store/modules/account/actions'

import * as S from './styles'

type AccountProps = {
  data: UserAccount
  showEmail?: boolean
  readOnly?: boolean
  isSingleAssociation?: boolean
  selectedIndex?: string
  onChangeAccount: (data: UserAccount) => Promise<void>
}

const Account = ({
  data,
  showEmail = false,
  readOnly = false,
  isSingleAssociation = false,
  selectedIndex = null,
  onChangeAccount
}: AccountProps) => {
  const { setDefaultUserAccountLoading } = useSelector(state => state.user)

  const dispatch = useDispatch()

  const decodedToken = getDecodedToken()

  const {
    name = '',
    header_color: bgLogo,
    logo,
    role,
    type,
    is_default_account,
    user_account_id
  } = data

  const names = name?.split(' ')

  const initals = names?.map(el => el[0])

  /**
   * @function handleChangeAccount
   */

  const handleChangeAccount = useCallback(async () => {
    onChangeAccount(data)
  }, [data, onChangeAccount])

  /**
   *
   * @function handleDefaultUserAccount
   */

  const handleDefaultUserAccount = useCallback(() => {
    dispatch(
      setDefaultUserAccounts({ user_account_id, is_default_account: true })
    )
  }, [dispatch, user_account_id])

  // Wrapper infos
  const WrapperInfos = ({ children }: { children: ReactNode }) =>
    readOnly ? (
      <span className="wrapperInfo">{children}</span>
    ) : (
      <button className="wrapperInfo active" onClick={handleChangeAccount}>
        {children}
      </button>
    )

  //
  const itemIsFocused = useMemo(
    () => selectedIndex === user_account_id,
    [selectedIndex, user_account_id]
  )

  return (
    <S.Container className={itemIsFocused ? 'focused' : null}>
      <WrapperInfos>
        <S.ContainerLogo
          bgLogo={bgLogo}
          className={type === 'grocery' ? 'rounded-circle' : 'rounded-3'}
        >
          {logo && <img src={logo} alt={`Logo ${name}`} />}

          {!logo &&
            initals
              .filter((i, index) => index < 2)
              .map((character, index) => (
                <span key={`key_${index}_${character}`}>{character}</span>
              ))}
        </S.ContainerLogo>

        <S.AccountInfo>
          <strong>{name}</strong>
          {role && (
            <small>
              <FormattedMessage id={role} />
            </small>
          )}

          {showEmail && <small className="email">{decodedToken.email}</small>}
        </S.AccountInfo>
      </WrapperInfos>

      {!isSingleAssociation && (
        <S.DefaultAccount
          as={is_default_account ? 'div' : 'button'}
          isDefault={is_default_account}
          {...(is_default_account ? {} : { onClick: handleDefaultUserAccount })}
        >
          {setDefaultUserAccountLoading && <Spinner />}

          {!setDefaultUserAccountLoading && (
            <>{is_default_account ? <AiFillStar /> : <AiOutlineStar />}</>
          )}
        </S.DefaultAccount>
      )}
    </S.Container>
  )
}

export default Account
