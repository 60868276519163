import { IconType } from 'react-icons'
import { FiTrendingUp as icon } from 'react-icons/fi'

import i18n from '~/i18n'

import { root as rootTexts, campaign as campaignTexts } from '../i18n/pt-br'
import AdsDashboard from '../pages/ads'
import AdSizes from '../pages/adSizes'
import CampaignCreate from '../pages/campaigns/create'
import RetailMediaCampaigns from '../pages/campaigns/list'
import CampaignsInReview from '../pages/campaigns/reviewList'
import RetailMediaCampaign from '../pages/campaigns/view'
import RetailMediaCheck from '../pages/check'
import RetaipMediaAdTypesPage from '../pages/config/adTypes'
import AdvertisersConfig from '../pages/config/advertisers'
import RetailMediaTermsOfServicePage from '../pages/config/termsOfService'
import Dashboard from '../pages/dashboard'
import * as industryPages from '../pages/industry'
import Links from '../pages/links'
import NetworkDashboard from '../pages/network'
import * as placementsPages from '../pages/placements'
import RetailMediaReport from '../pages/reports'
import { TvRoutes } from '../pages/tv/routes'
import WalletsList from '../pages/walltets/list'
import WalletRecharge from '../pages/walltets/recharge'
import WalletView from '../pages/walltets/view'

/**
 * 1. Dashboard
 */

const dashboard: ChildMenuItem[] = [
  {
    path: '/retail-media',
    id: 'b1ced6c3-13ba-444a-bbd1-13a83d637ee2',
    name: rootTexts.dashboard,
    contentFullWidth: true,
    component: Dashboard,
    hideMenu: true,
    containsHome: true
  }
]

/**
 * 2. Listagem de vínculos (publisher - apenas rede / anunciantes)
 */
const links: ChildMenuItem[] = [
  {
    path: '/retail-media/publishers',
    sidebarStartsClosed: true,
    id: null, // rota-sem-id
    name: rootTexts.publishers,
    contentFullWidth: true,
    component: Links,
    onlyNewtail: true,
    onlyAdvertiser: true
  },
  {
    path: '/retail-media/advertisers',
    sidebarStartsClosed: true,
    id: '02069c18-fd76-4f56-a7a0-a1ccbdf96947',
    name: rootTexts.advertisers,
    contentFullWidth: true,
    component: Links,
    onlyPublisher: true
  }
]

/**
 * 3. Listagem de campanhas
 */
const campaigns: ChildMenuItem[] = [
  {
    sidebarStartsClosed: true,
    id: null, // rota-sem-id
    path: '/retail-media/campaigns',
    name: campaignTexts.menu.campaigns,
    contentFullWidth: true,
    component: RetailMediaCampaigns
  },
  /**
   * 3.1. Listagem de campanhas em revisão
   */
  {
    sidebarStartsClosed: true,
    id: null,
    path: '/retail-media/campaigns/review',
    name: rootTexts.campaigns,
    contentFullWidth: true,
    component: CampaignsInReview,
    hideMenu: true,
    onlyPublisher: true
  },
  /**
   * 3.2. Visualização de campanha
   */
  {
    sidebarStartsClosed: true,
    id: null, // rota-sem-id
    path: '/retail-media/campaign/view/:id',
    name: campaignTexts.menu.view,
    hideMenu: true,
    contentFullWidth: true,
    component: RetailMediaCampaign
  },
  /**
   * 3.3. Criação de campanha
   */
  {
    id: null, // rota-sem-id
    path: '/retail-media/campaign/create',
    name: campaignTexts.menu.create,
    hideMenu: true,
    component: CampaignCreate,
    onlyAdvertiser: true
  }
]

/**
 * 4. Anúncios
 */

const ads: ChildMenuItem[] = [
  {
    sidebarStartsClosed: true,
    id: null, // rota-sem-id
    path: '/retail-media/ads',
    name: campaignTexts.menu.ads,
    contentFullWidth: true,
    component: AdsDashboard
  }
]

/**
 * 5. Rede
 */

const subPublishers: ChildMenuItem[] = [
  {
    path: '/retail-media/network',
    sidebarStartsClosed: true,
    id: null,
    name: rootTexts.network,
    contentFullWidth: true,
    component: NetworkDashboard,
    onlyNetwork: true,
    onlyPublisher: true
  }
]

/**
 * 6. Carteiras
 */
const wallets: ChildMenuItem[] = [
  {
    id: null, // rota-sem-id
    path: '/retail-media/wallets',
    name: campaignTexts.menu.wallet,
    component: WalletsList
  },
  {
    id: '2e0ef368-6ac5-4c10-b8e3-88e1721b7382',
    path: '/retail-media/wallet/:id/:section?',
    name: campaignTexts.menu.wallet,
    component: WalletView,
    hideMenu: true
  },
  {
    id: '2e0ef368-6ac5-4c10-b8e3-88e1721b7382',
    path: '/retail-media/wallet/:id/recharge',
    name: campaignTexts.menu.wallet,
    component: WalletRecharge,
    hideMenu: true
  },
  {
    id: null, // rota-sem-id
    path: '/retail-media/wallet/:id/transaction/:transactionId',
    name: campaignTexts.menu.wallet,
    hideMenu: true,
    component: industryPages.WalletPages.RechargeTransaction,
    onlyAdvertiser: true
  }
]

/**
 * 7. Placements
 */
const placements: ChildMenuItem[] = [
  {
    id: '334211a3-4385-11ef-99b5-0aa6baaecec9',
    path: '/retail-media/placements',
    onlyPublisher: true,
    name: `- ${i18n.t('rm:placements.list.pageTitle')}`,
    pageTitle: i18n.t('rm:placements.list.pageTitle'),
    component: placementsPages.list
  },
  {
    id: '334211a3-4385-11ef-99b5-0aa6baaecec9',
    path: '/retail-media/placements/:id/edit',
    onlyPublisher: true,
    pageTitle: i18n.t('rm:placements.edit.pageTitle'),
    name: i18n.t('rm:placements.edit.pageTitle'),
    component: placementsPages.list,
    hideMenu: true
  },
  {
    id: '334211a3-4385-11ef-99b5-0aa6baaecec9',
    path: '/retail-media/placements/create',
    onlyPublisher: true,
    name: i18n.t('rm:placements.create.pageTitle'),
    pageTitle: i18n.t('rm:placements.create.pageTitle'),
    component: placementsPages.create,
    hideMenu: true
  }
]

/**
 * 8. Tamanhos de mídias
 */
const adSizes: ChildMenuItem[] = [
  {
    id: 'ee4d83b6-dd83-4169-8109-e1519e76195d', // rota-sem-id
    path: '/retail-media/ad-sizes',
    onlyPublisher: true,
    name: `- ${i18n.t('rm:adsize.page.title')}`,
    pageTitle: i18n.t('rm:adsize.page.title'),
    component: AdSizes
  }
]

/**
 *  9. Relatórios
 */
const reports: ChildMenuItem[] = [
  {
    id: null,
    name: i18n.t('rm:Reports'),
    path: '#',
    hasChildren: true
  },
  {
    id: null, // rota-sem-id
    path: '/retail-media/report/ads',
    name: `- ${i18n.t('rm:adsReports')}`,
    pageTitle: i18n.t('rm:adsReports'),
    contentFullWidth: true,
    component: RetailMediaReport
  },
  {
    id: null, // rota-sem-id
    path: '/retail-media/report/overview',
    name: `- ${i18n.t('rm:managementReports')}`,
    pageTitle: i18n.t('rm:managementReports'),
    contentFullWidth: true,
    component: RetailMediaReport,
    onlyPublisher: true
  }
]

/**
 *  10. Configurações
 */
const configs: ChildMenuItem[] = [
  {
    id: null,
    name: i18n.t('rm:Configurations'),
    path: '#',
    onlyPublisher: true,
    hasChildren: true
  },
  {
    id: null,
    name: `- ${i18n.t('rm:config.advertisers.menu')}`,
    pageTitle: i18n.t('rm:config.advertisers.pageTitle'),
    path: '/retail-media/config/advertisers',
    component: AdvertisersConfig,
    onlyPublisher: true
  },
  {
    id: null,
    name: `- ${i18n.t('rm:config.adTypes.menu')}`,
    pageTitle: i18n.t('rm:config.adTypes.pageTitle'),
    path: '/retail-media/config/ad-types',
    component: RetaipMediaAdTypesPage,
    onlyPublisher: true
  },
  {
    id: null,
    name: `- ${i18n.t('rm:config.termsOfService.menu')}`,
    pageTitle: i18n.t('rm:config.termsOfService.pageTitle'),
    path: '/retail-media/config/terms-of-service',
    component: RetailMediaTermsOfServicePage,
    onlyPublisher: true
  }
  // {
  //   id: null,
  //   name: `- ${i18n.t('rm:config.campaignPermissions.menu')}`,
  //   pageTitle: i18n.t('rm:config.campaignPermissions.pageTitle'),
  //   path: '/retail-media/config/campaign-permissions',
  //   component: RetailMediaCampaignPermissionsPage,
  //   onlyPublisher: true
  // }
]

/**
 * 9999. Extras
 * Demais (MVP e uso interno)
 */
const extras: ChildMenuItem[] = [
  // Newtail Media TV
  {
    id: null,
    path: '/activate',
    name: rootTexts.dashboard,
    contentFullWidth: true,
    component: TvRoutes.activate,
    hideMenu: true,
    onlyPublisher: true
  },
  {
    id: null,
    path: '/activate/:device_token',
    name: rootTexts.dashboard,
    contentFullWidth: true,
    component: TvRoutes.activate,
    hideMenu: true,
    onlyPublisher: true
  },
  {
    id: null,
    path: '/retail-media/tv/devices',
    name: 'TVs Ativadas',
    contentFullWidth: true,
    component: TvRoutes.devices,
    hideMenu: true,
    onlyPublisher: true
  },
  // Placements

  /**
   * Check
   */
  {
    sidebarStartsClosed: true,
    id: null, // rota-sem-id
    path: '/retail-media/check',
    name: 'Monitoramento de métricas',
    contentFullWidth: true,
    component: RetailMediaCheck,
    hideMenu: true,
    onlyPublisher: true
  }
]

export const retailMedialRoutes: RootMenuItem<IconType> = {
  id: 'b1ced6c3-13ba-444a-bbd1-13a83d637ee2',
  path: '/retail-media',
  name: rootTexts.label,
  icon: icon,
  children: [].concat(
    // dashboard,
    links,
    campaigns,
    ads,
    subPublishers,
    wallets,
    reports,
    configs,
    placements,
    adSizes,
    extras
  )
}
