/* eslint-disable react/display-name */

import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'

import { useUserList } from '../../pages/List/hooks/useUsersList'
import { schema } from './schema'

const UsersTable = () => {
  const {
    isPending,
    data,
    total,
    page,
    error,
    quantity,
    handlePagination,
    handleItemsPerPageChange
  } = useUserList()

  return (
    <>
      {error && (
        <AlertInfo text={t('users:userList.errorAlert')} template="danger" />
      )}

      <Loading status={isPending}>{t('users:userLoading')}</Loading>

      {data && (
        <Table total={total} schema={schema} data={data} smallerRowPadding />
      )}

      <ListPagination
        total={total}
        label={total > 1 ? t('users:users') : t('users:user')}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default UsersTable
