import Panel from '~/components/Panel'

const DetailsSponsoredBrands: React.FC<ViewCampaignProps> = ({ data }) => {
  const videoUrl = data?.ads?.data?.[0]?.settings?.media_url

  return (
    <Panel title="Mídia da campanha">
      <video controls className="vh-50">
        <source src={videoUrl} type="video/mp4" />
      </video>
    </Panel>
  )
}

export default DetailsSponsoredBrands
