import { IconType } from 'react-icons'
import { FiTruck as icon } from 'react-icons/fi'

import {
  LogisticSlots,
  LogisticRegions,
  DeliveryDashboard
} from '~/pages/logistic'

export const logistic: RootMenuItem<IconType> = {
  id: '2fc2e6ac-eb88-48c5-9a3f-d9cba2b010fe',
  path: '/logistic',
  name: 'Logística',
  pageTitle: 'Logística',
  icon,
  children: [
    {
      id: null, // rota-sem-id
      path: '/logistic/slots/:mode/:locationId/:regionId',
      name: 'Slots de entrega - Definição de uma região',
      pageTitle: 'Slots de entrega - Definição de uma região',
      component: LogisticSlots,
      contentFullWidth: true,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/logistic/slots/:mode/:locationId/',
      name: 'Slots de entrega - Definição do local',
      pageTitle: 'Slots de entrega - Definição do local',
      component: LogisticSlots,
      contentFullWidth: true,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/logistic/slots/:mode/',
      name: 'Slots de entrega - Definição global',
      pageTitle: 'Slots de entrega - Definição global',
      contentFullWidth: true,
      component: LogisticSlots,
      hideMenu: true
    },
    {
      id: '6df5da36-496f-421e-bcf5-a6748d6ff848',
      path: '/logistic/slots/',
      name: 'Slots de entrega',
      pageTitle: 'Slots de entrega',
      contentFullWidth: true,
      maxHeightViewport: true,
      component: LogisticSlots
    },
    // Regions
    {
      id: '4787a9db-7374-4d44-a9eb-ee16b71d792c',
      path: '/logistic/:id/regions',
      name: 'Regiões de entrega',
      pageTitle: 'Regiões de entrega',
      contentFullScreen: true,
      contentFullWidth: true,
      maxHeightViewport: true,
      component: LogisticRegions,
      hideMenu: true
    },
    {
      id: '4787a9db-7374-4d44-a9eb-ee16b71d792c',
      path: '/logistic/all/regions',
      name: 'Regiões de entrega',
      pageTitle: 'Regiões de entrega',
      contentFullScreen: true,
      contentFullWidth: true,
      maxHeightViewport: true,
      component: LogisticRegions
    },
    {
      id: '4787a9db-7374-4d44-a9eb-ee16b71d792c',
      path: '/logistic/:id/regions/:mode',
      name: 'Regiões de entrega',
      pageTitle: 'Regiões de entrega',
      contentFullScreen: true,
      contentFullWidth: true,
      maxHeightViewport: true,
      hideMenu: true,
      component: LogisticRegions,
      permissionType: 'admin'
    },
    {
      id: '81874435-65b7-4bfa-9185-83adb61ec914',
      path: '/logistic/delivery-dashboard/',
      name: 'Relatório de entregas',
      pageTitle: 'Relatório de entregas',
      component: DeliveryDashboard,
      contentFullWidth: true
    }
  ]
}
