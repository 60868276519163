import { product } from '../constants'

const index = {
  addProduct: product.add,
  deleteProduct: product.remove,
  deleteAllProducts: product.remove_all,
  addProductsBulkPending: `${product.addBulk}_PENDING`,
  addProductsBulkFulfilled: `${product.addBulk}_FULFILLED`,
  addProductsBulkRejected: `${product.addBulk}_REJECTED`
}

const reducerKey = 'products'

export const handleProducts: ActionProps = {
  [index.addProduct]: ({ draft, action }) => {
    const product = action.payload

    const oldSelectedProducts = draft.campaign[reducerKey].selectecProducts

    draft.campaign[reducerKey].selectecProducts = [
      product,
      ...oldSelectedProducts
    ]
  },
  [index.deleteProduct]: ({ draft, action }) => {
    const oldSelectedProducts = draft.campaign[reducerKey].selectecProducts
    const removeId = action.payload.id

    const removeSku = action.payload.product_sku

    const filteredProducts = oldSelectedProducts.filter(item =>
      item.id ? item.id !== removeId : item.product_sku !== removeSku
    )

    draft.campaign[reducerKey].selectecProducts = filteredProducts
  },
  [index.deleteAllProducts]: ({ draft, action }) => {
    const isEdit = action.payload
    const oldSelectedProducts = draft.campaign[reducerKey].selectecProducts

    if (!isEdit) {
      draft.campaign[reducerKey].selectecProducts = []
    } else {
      const products = oldSelectedProducts.filter(
        item => item.item_disabled === true
      )

      draft.campaign[reducerKey].selectecProducts = products
    }
  },
  //
  [index.addProductsBulkPending]: ({ draft }) => {
    draft.campaign[reducerKey].loadingAddBulk = true
    draft.campaign[reducerKey].rejectedProducts = null
  },
  //
  [index.addProductsBulkFulfilled]: ({ draft, action }) => {
    const oldSelectedProducts = draft.campaign[reducerKey].selectecProducts

    const addedProducts = action.payload.data

    const findIndexOffer = offer =>
      oldSelectedProducts.findIndex(item =>
        offer.id ? offer.id === item.id : offer.product_sku === item.product_sku
      )

    const selectedOffers = addedProducts.filter(
      item => findIndexOffer(item) === -1
    )

    // Handle rejected products
    const allRequestedProductSkus = action.values.product_skus

    const rejectedProducts = allRequestedProductSkus.filter(
      item =>
        !(
          addedProducts.findIndex(
            offer => offer.product_sku === item || offer.id === item
          ) > -1
        )
    )

    draft.campaign[reducerKey].selectecProducts = [
      ...selectedOffers,
      ...oldSelectedProducts
    ]

    draft.campaign[reducerKey].rejectedProducts = rejectedProducts

    draft.campaign[reducerKey].loadingAddBulk = false
  },
  [index.addProductsBulkRejected]: ({ draft }) => {
    draft.campaign[reducerKey].loadingAddBulk = false
  }
}
