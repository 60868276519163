import React, { useEffect, useMemo } from 'react'
import { FiBarChart, FiEye, FiMessageSquare, FiUser } from 'react-icons/fi'
import { TbCrown } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { DropdownToggle } from 'reactstrap'

import { getDecodedToken, getRole } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { useAppInfo } from '~/hooks/useAppInfo'
import { updateAccountInfo } from '~/store/modules/account/actions'

import AvatarDefaultImg from '../AvatarDefaultImg'

import * as S from './styles'

const NavbarUserAvatar = () => {
  const dispatch = useDispatch()

  const { isWhiteLabel, headerTheme } = useAppInfo()

  const decodedToken = getDecodedToken()
  const accountInfo = getAccountInfo()

  useEffect(() => {
    if (!accountInfo) {
      dispatch(updateAccountInfo())
    }
  }, [accountInfo, dispatch])

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])
  const accountLogo = useMemo(() => accountInfo?.logo, [accountInfo])
  const bgLogo = useMemo(() => accountInfo?.bgLogo, [accountInfo])

  const role = getRole()

  const icons = {
    admin: TbCrown,
    user: FiUser,
    guest: FiEye,
    agent: FiMessageSquare,
    peao: FiBarChart,
    personal_service: FiBarChart
  }

  const Icon = role ? icons[role] : <></>

  return (
    <S.NavbarUserAvatarWrapper>
      <DropdownToggle
        className="user-info bg-transparent border-0"
        color={isWhiteLabel && headerTheme === 'light' ? 'white' : 'primary'}
      >
        <div className="user_info-wrapper">
          <div className="user-name">
            <span className="name">{decodedToken.name}</span>{' '}
            <span className="user-role-icon">
              <Icon />
            </span>
          </div>

          {accountInfo && <span className="account-name">{accountName}</span>}
        </div>

        <AvatarDefaultImg
          name={decodedToken.name}
          accountLogo={accountLogo}
          accountName={accountName}
          bgLogo={bgLogo}
        />
      </DropdownToggle>
    </S.NavbarUserAvatarWrapper>
  )
}

export default NavbarUserAvatar
